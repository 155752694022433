import { ReactElement } from 'react'
import { AgxButton, AgxRow, AgxHeader, Images } from '@urbanx/agx-ui-components'
import './popup.css'
import { Modal } from 'react-bootstrap'

export interface ActionButton {
  title: string
  isPrimary?: boolean
  isDanger?: boolean
  closePopupOnClick?: boolean
  clickHandler?: () => void
}

export interface PopupProps {
  title: string
  actionButtons: ActionButton[]
  isOpen: boolean
  size: { widthInPX: number }
  onClose: () => void
  children: ReactElement | ReactElement[]
  centered?: boolean
  icon?: ReactElement
}
const Popup = (props: PopupProps) => {
  const {
    title,
    actionButtons,
    children,
    onClose,
    size,
    isOpen = false,
    centered,
    icon
  } = props

  const buttonClickHandler = (button: ActionButton) => {
    const { clickHandler, closePopupOnClick } = button

    if (clickHandler) clickHandler()
    if (closePopupOnClick) {
      onClose()
    }
  }

  return (
    <Modal show={isOpen} onHide={() => onClose()} centered={!!centered}>
      <Modal.Body>
        <div>
          <div
            className="showPopup"
            style={{
              width: `${size.widthInPX}px`,
            }}
          >
            <div>
              {icon && <>{icon}</>}
              <Images.CloseOutline
                onClick={() => onClose()}
                className="closeButton__popup"
              />
            </div>
            <div className="flexContainer__popup">
              <AgxRow>
                {title && <AgxHeader size={3}>{title}</AgxHeader>}
              </AgxRow>

              <div className="contentContainer__popup">{children}</div>

              <AgxRow>
                <div className="actionButtonContainer__popup">
                  <div className="nonDangerButtonsContainer__popup">
                    {actionButtons &&
                      actionButtons
                        .filter((b) => !b.isDanger)
                        .map((button) => (
                          <AgxButton
                            key={button.title}
                            onClick={() => buttonClickHandler(button)}
                            text={button.title}
                            large
                            primary={button.isPrimary}
                            hollow={!button.isPrimary}
                          />
                        ))}
                  </div>
                  <div className="dangerButtonsContainer__popup">
                    {actionButtons &&
                      actionButtons
                        .filter((b) => b.isDanger)
                        .map((button) => (
                          <AgxButton
                            key={button.title}
                            onClick={() => buttonClickHandler(button)}
                            text={button.title}
                            large
                            naked
                            danger
                          />
                        ))}
                  </div>
                </div>
              </AgxRow>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default Popup
