import { ApiRequestBody } from "interfaces/ApiRequestBody"
import { AgencyUrbanXTeamRequest } from "types/staff"
import AgencyService from "services/agenciesService"

export interface SetUrbanXTeamForAgencyRequest extends ApiRequestBody {
    agencyId: string
    urbanXTeam: AgencyUrbanXTeamRequest | undefined
}

const SetUrbanXTeamForAgency = async (
    requestBody: SetUrbanXTeamForAgencyRequest
): Promise<void> => {
    const { getAuthToken } = requestBody

    const token = await getAuthToken()
    if (!token) return

    const service = new AgencyService(token)

    if (!service) return

    await service.agencyService.put('SetUrbanXTeamForAgency', {
        agencyId: requestBody.agencyId,
        urbanXTeam: requestBody.urbanXTeam
    })
}
  
export default SetUrbanXTeamForAgency