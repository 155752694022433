import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxSlideUpModal
} from '@urbanx/agx-ui-components'
import { StaffRoleAssignmentsReponse } from 'services/functions/agency/getStaffMembersAssignedToAgencies'
import { StaffRole } from 'types/staff'

interface Props {
  staffRoleAssignments: StaffRoleAssignmentsReponse
  setShowModal: (showModal: boolean) => void
  updateRoleAssignments: () => void
}

const formatRoleName = (role: StaffRole | undefined) => {
  switch (role) {
    case StaffRole.BusinessManager:
      return 'Business Manager'
    case StaffRole.ListingsAdmin:
      return 'Listings Administrator'
    case StaffRole.SalesAdmin:
      return 'Sales Administrator'
    case StaffRole.MarketingExpert:
      return 'Marketing Expert'
    default:
      throw new Error('Invalid role');
  }
}

const empahasizeText = (text: string) => <b><u>{text}</u></b>

export const RoleAssignmentModal = (props: Props) => {
  const {
    staffRoleAssignments: {
      staffRoleAssignments,
      staffRole,
    },
    setShowModal,
    updateRoleAssignments
  }
  = props

  const actionButtons: React.ReactNode[] = [
    <AgxButton
      key={'perform-role-assignment'}
      primary
      large
      text={`Replace ${formatRoleName(staffRole)}`}
      onClick={() => updateRoleAssignments()}
    />,
    <AgxButton
      key={'cancel-role-assignment'}
      hollow
      large
      text={'No, cancel'}
      onClick={() => setShowModal(false)}
    />,
  ];

  const warningContent =
    <>
      <AgxColumn>
        <AgxHeader size={3} extraClasses='roles-replace-header'>Replace current {formatRoleName(staffRole)}?</AgxHeader>
      </AgxColumn>
      { staffRoleAssignments && staffRoleAssignments.map((x) =>
        <AgxColumn key ={x.agencyId}>
          <AgxBodyText>
            {empahasizeText(x.agencyName)} current {formatRoleName(staffRole)} is {empahasizeText(`${x.firstName} ${x.lastName}`)}
          </AgxBodyText>
        </AgxColumn>
      )}
    </>

  return (
    <AgxSlideUpModal
      closeButton
      content={warningContent}
      desktop
      onClose={() => setShowModal(false)}
      actionButtons={actionButtons}
    />
  )
}

export default RoleAssignmentModal
