import React from 'react'
import {
  AgxLabel,
  AgxDivider,
  UploadedDocument,
  DocumentTypes,
} from '@urbanx/agx-ui-components'
import DocumentRow from './DocumentRow'

interface Props {
  documents: UploadedDocument[]
  downloadDocument: (document: UploadedDocument) => void
  getDocumentTypeLabel: (documentType: string) => string
}

const hiddenDocumentTypeLabels = [DocumentTypes.PowerOfAttorney]

const DocumentSection = (props: Props) => {
  const { documents, downloadDocument, getDocumentTypeLabel } = props
  return (
    <>
      {documents.map((document, innerIndex) => (
        <React.Fragment key={document.documentId}>
          {!hiddenDocumentTypeLabels.includes(document.documentType) && (
            <React.Fragment>
              {innerIndex > 0 && <AgxDivider />}
              <AgxLabel medium>
                {getDocumentTypeLabel(document.documentType)}
              </AgxLabel>
            </React.Fragment>
          )}
          <DocumentRow
            document={document}
            downloadDocument={downloadDocument}
          />
        </React.Fragment>
      ))}
    </>
  )
}

export default DocumentSection
