import { QueryKey } from '@tanstack/react-query'
import { DocumentDefaults } from 'types/documentDefaults'
import AgencyService from '../../agenciesService'

const GetAgencyDocumentDefaults = async (
  {
    queryKey,
  }: {
    queryKey: QueryKey
  },
  getAuthToken: () => Promise<string | void>
): Promise<DocumentDefaults | undefined> => {
  const agencyId = queryKey[1] as string

  if (!agencyId) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get('GetAgencyDocumentDefaults', {
    agencyId,
  })

  return result.data
}

export default GetAgencyDocumentDefaults
