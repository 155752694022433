import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  AgxButton,
  AgxColumn,
  AgxConditionalTextInput,
  AgxLabel,
  AgxRow,
  AgxTextInput,
  AgxToast,
  InputType,
} from '@urbanx/agx-ui-components'
import { FormPrompt } from 'components/FormPrompt'
import { MenuTitles } from 'constants/menu'
import agencyContext from 'contexts/agencyContext'
import userContext from 'contexts/userContext'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { ContentLayout, PageLayout } from 'layout'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GetAllAgencies, UpdateAgencyDocumentDefaults } from 'services'
import GetAgencyDocumentDefaults from 'services/functions/documentDefault/getAgencyDocumentDefaults'
import { Agency } from 'types/agency'
import { AgxToastState } from 'types/commonTypes'
import { DocumentDefaults } from 'types/documentDefaults'

const DocumentDefaultsView = () => {
  const user = useContext(userContext)
  const [selectedAgency, setSelectedAgency] = useContext(agencyContext)
  const { agencyId } = useParams()
  const [, getAuthToken] = useAzureAuth()
  const [formLoaded, setFormLoaded] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const queryClient = useQueryClient()
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const [documentDefaults, setDocumentDefaults] = useState<DocumentDefaults>({
    agencyId: agencyId || '',
    contractsEmail: '',
    settlementPlace: '',
    privacyPolicyLink: '',
    titleEncumbrances: '',
  })

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const {
    data: agencyDocumentDefaults,
    isFetched,
    isLoading,
  } = useQuery<DocumentDefaults | undefined>({
    queryKey: ['getAgencyDocumentDefaults', agencyId],
    queryFn: (queryKey) => GetAgencyDocumentDefaults(queryKey, getAuthToken),
    enabled: !!agencyId,
  })

  const { mutate: updateAgencyDocumentDefaults } = useMutation(
    UpdateAgencyDocumentDefaults,
    {
      onSuccess: () => {
        setIsFormDirty(false)
        queryClient.invalidateQueries({
          queryKey: ['getAgencyDocumentDefaults', agencyId],
        })
        updateToastState({
          color: 'success',
          message: 'Document defaults saved successfully',
          open: true,
        })
      },
      onError: () => {
        updateToastState({
          color: 'error',
          message: 'Error saving document defaults',
          open: true,
        })
      },
    }
  )

  const saveChanges = () => {
    updateAgencyDocumentDefaults({
      getAuthToken,
      ...documentDefaults,
      agencyId: agencyId || '',
    })
  }

  useEffect(() => {
    if (agencyId && selectedAgency?.id !== agencyId) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies, agencyId])

  useEffect(() => {
    if (isFetched && agencyDocumentDefaults) {
      setDocumentDefaults(agencyDocumentDefaults)
      setIsFormDirty(false)

      if (agencyDocumentDefaults.contractsEmail !== null) setFormLoaded(true)
    }
  }, [agencyDocumentDefaults])

  if (!isFetched || isLoading || !formLoaded) return null

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Document defaults"
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.DOCUMENT_DEFAULTS}
      >
        <FormPrompt hasUnsavedChanges={isFormDirty} />
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween centered extraClasses="borderBottomContainer">
            <AgxLabel large>Document defaults</AgxLabel>
            <AgxButton
              text="Save Changes"
              medium
              primary
              onClick={saveChanges}
            />
          </AgxRow>
          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Listing Agreement defaults</AgxLabel>
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="privacyPolicyLink"
                label="Privacy Policy Link"
                stretch
                noOptionalLabel
                defaultValue={documentDefaults.privacyPolicyLink || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setDocumentDefaults((previousState) => ({
                    ...previousState,
                    privacyPolicyLink: value,
                  }))
                }
              />
            </AgxRow>
          </AgxColumn>
          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Contract defaults</AgxLabel>
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="contractEmail"
                label="Contract Email"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={documentDefaults.contractsEmail || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setDocumentDefaults((previousState) => ({
                    ...previousState,
                    contractsEmail: value,
                  }))
                }
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="SettlementPlace"
                label="Place for Settlement"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={documentDefaults.settlementPlace}
                onInputValueChange={({ value }: { value: string }) =>
                  setDocumentDefaults((previousState) => ({
                    ...previousState,
                    settlementPlace: value,
                  }))
                }
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxConditionalTextInput
                id={'EncumbrancesEasements'}
                key={`${'EncumbrancesEasements'}`}
                onValueChanged={({ value }) =>
                  setDocumentDefaults((previousState) => ({
                    ...previousState,
                    titleEncumbrances: value,
                  }))
                }
                defaultValue={documentDefaults.titleEncumbrances}
                label={'Title Encumbrances'}
                inputType={InputType.TextArea}
                noLabel={'No Title encumbrances'}
                yesLabel={'Yes, property is subject to Title encumbrances'}
                placeholder="Encumbrance details"
                noTextInputValue="Nil"
                flexibleHeight
                reverseLabelOrder
                showTextInputOnYesSelection
              />
            </AgxRow>
          </AgxColumn>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default DocumentDefaultsView
