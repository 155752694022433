import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import { Staff } from '../../../types/staff'
import StaffService from '../../staffService'

interface UpdateStaffMemberRequest extends ApiRequestBody {
  staff: Staff
}

const UpdateStaffMember = async (
  requestBody: UpdateStaffMemberRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new StaffService(token)

  if (!service) return

  const { staff } = requestBody

  await service.staffService.put('UpdateStaffMember', staff)
}

export default UpdateStaffMember
