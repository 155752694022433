import React, { useMemo, useState } from 'react'
import {
  AgxLabel,
  AgxRow,
  AgxColumn,
  AgxInputText,
  AgxBodyText,
  Images,
} from '@urbanx/agx-ui-components'

import './fileUpload.css'
import { formatFileSize } from '../../utils/formatFileSize'
import clsx from 'clsx'

interface FileUploadProps {
  id: string
  wide: boolean
  uploadFile: (formdata: File) => void
  title?: string
  fileData?: any
  extensions?: string[]
  buttonText?: string
  dark?: boolean
  hideIcon?: boolean
}

const FileUpload = (props: FileUploadProps) => {
  const {
    id,
    fileData,
    uploadFile,
    title,
    wide = false,
    extensions = [],
    buttonText,
    dark = false,
    hideIcon = false,
  } = props
  const [selectedFile, setSelectedFile] = useState(fileData ?? null)
  const [errorMessage, setErrorMessage] = useState('')

  const classes = clsx(
    selectedFile && 'fileSelected',
    !selectedFile && 'fileUpload',
    wide && 'wide',
    dark && 'dark'
  )

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    setErrorMessage('')

    if (file) {
      const fileExtension = file.name.split('.').pop()
      if (!extensions.includes(fileExtension)) {
        return setErrorMessage(
          `Invalid file type. Please upload a file with the following extensions: ${extensions.join(
            ', '
          )}`
        )
      }
    }

    setSelectedFile(file)

    if (file) {
      uploadFile(file)
    }
  }

  const handleDeleteFile = async () => {
    setSelectedFile(null)
  }

  const inputExtensions = useMemo(() => {
    return extensions?.map((extension) => `.${extension}`)?.join(',')
  }, [extensions])

  return (
    <AgxColumn largeGap fill={selectedFile}>
      {title && <AgxLabel medium>{title}</AgxLabel>}
      {!selectedFile && (
        <label className={classes}>
          <AgxRow centered spaceBetween fill>
            <AgxRow centered mediumGap fill>
              {!hideIcon && (
                <span>
                  <Images.CloudUpload />
                </span>
              )}
              <input
                type="file"
                onChange={handleFileUpload}
                accept={inputExtensions}
              />
              <AgxInputText extraClasses='fileUpload-text' medium data-testid={`agx-testFileLabel${id}`}>
                {buttonText ?? 'Choose file'}
              </AgxInputText>
            </AgxRow>
          </AgxRow>
        </label>
      )}
      {selectedFile && (
        <label className={classes}>
          <AgxRow fill spaceBetween centered>
            <AgxRow fill mediumGap centered>
              <div className="file-document-icon">
                <Images.FileTextOutline />
              </div>
              <AgxInputText medium data-testid={`agx-testFileLabel${id}`}>
                {selectedFile.name}
              </AgxInputText>
              <AgxBodyText extraClasses='fileUpload-text' small neutralGrayColor>
                {formatFileSize(selectedFile.size)}
              </AgxBodyText>
            </AgxRow>
            <AgxRow onClick={() => handleDeleteFile()}>
              <Images.CrossFill />
            </AgxRow>
          </AgxRow>
        </label>
      )}
      {errorMessage && (
        <AgxBodyText small extraClasses="error">
          {errorMessage}
        </AgxBodyText>
      )}
    </AgxColumn>
  )
}

export default FileUpload
