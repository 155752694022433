import {
  NetworkPartnershipMetricData,
  NetworkStateMetricDataRequest,
} from 'types/networkStatistics'
import AgencyService from 'services/agenciesService'

const getNetworkPartnershipMetrics = async (
  dates: NetworkStateMetricDataRequest,
  getAuthToken: () => Promise<string | void>
): Promise<NetworkPartnershipMetricData | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const service = new AgencyService(token)
  if (!service) return undefined

  const requestParameters = {
    periodStart: dates.periodStart,
    periodEnd: dates.periodEnd,
  }

  const result = await service.agencyService.get(
    'GetNetworkPartnershipMetrics',
    requestParameters
  )
  return result.data
}

export default getNetworkPartnershipMetrics
