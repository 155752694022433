import { AgxButton, AgxTextInput } from '@urbanx/agx-ui-components'
import Panel from 'components/panel/Panel'
import './editRexId.scss'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AgentRexDetails } from 'types/rex'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { GetAgentRexDetails, UpdateAgentRexDetails } from 'services'
import { AgxToastState } from 'types/commonTypes'

interface EditRexIdProps {
  agentId: string
  updateToastState: (toastState: AgxToastState) => void
}

const EditRexId = ({ agentId, updateToastState }: EditRexIdProps) => {
  const [, getAuthToken] = useAzureAuth()
  const queryClient = useQueryClient()

  const { data: agentRexDetails, isFetched } = useQuery<
    AgentRexDetails | undefined
  >({
    queryKey: ['agentRexDetails', agentId],
    queryFn: (queryKey) => GetAgentRexDetails(queryKey, getAuthToken),
  })

  const [rexEmail, setRexEmail] = useState(
    agentRexDetails?.rexUserEmailAddress ?? ''
  )

  const showSavingError = (error: any) => {
    console.error('An error occurred while updated the rex id.')

    if (error) console.error(error)

    updateToastState({
      color: 'error',
      message: error?.message ?? 'An error occurred while updating the rex id.',
      open: true,
    })
  }

  const { mutate: updateAgentRexDetails } = useMutation(UpdateAgentRexDetails, {
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ['agentRexDetails', agentId],
      })
      if (!result) {
        updateToastState({
          color: 'success',
          message: 'Rex ID updated successfully',
          open: true,
        })
      } else {
        showSavingError(result)
      }
    },
    onError: (error) => {
      showSavingError(error)
    },
  })

  const onSaveChanges = () => {
    updateAgentRexDetails({
      getAuthToken,
      AgentId: agentId,
      RexUserEmailAddress: rexEmail.length > 0 ? rexEmail : agentId,
    })
  }

  useEffect(() => {
    if (isFetched && agentRexDetails) {
      setRexEmail(agentRexDetails.rexUserEmailAddress ?? '')
    }
  }, [agentRexDetails, isFetched])

  return (
    <Panel
      title="Rex ID"
      halfWidth
      actionContent={
        <AgxButton text="Save Changes" medium primary onClick={onSaveChanges} />
      }
    >
      {agentRexDetails && isFetched ? (
        <AgxTextInput
          id="rexIdEmailInput"
          email
          onInputValueChange={({ value }) => setRexEmail(value)}
          defaultValue={
            rexEmail === ''
              ? agentRexDetails?.rexUserEmailAddress ?? ''
              : rexEmail
          }
          label="Rex ID"
          stretch
          required
          placeholder={agentId}
        />
      ) : (
        <></>
      )}
    </Panel>
  )
}

export default EditRexId
