import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import DomainService from '../../domainService'

interface UpdateDomainCodeRequest extends ApiRequestBody {
  AgencyId?: string
  Code?: number | null
}

const UpdateDomainCode = async (
  requestBody: UpdateDomainCodeRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new DomainService(token)

  if (!service) return

  await service.domainService.post('SetAgencyCode', requestBody)
}

export default UpdateDomainCode
