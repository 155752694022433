import { QueryKey } from '@tanstack/react-query'
import RexService from '../../rexService'
import { AgentRexDetails } from '../../../types/rex'

const getAgentRexDetails = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<AgentRexDetails | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agentId = queryKey[1] as string
  const service = new RexService(token)

  if (!service) return undefined

  const result = await service.rexService.get('GetAgentRexDetails', { agentId })

  return result.data
}

export default getAgentRexDetails
