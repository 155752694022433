import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import { StaffRole } from '../../../types/staff'
import AgencyService from '../../agenciesService'

export interface StaffRoleAssignment {
  staffMemberId: string
  firstName: string
  lastName: string
  agencyId: string
  agencyName: string
}

export interface StaffRoleAssignmentsReponse {
  staffRole?: StaffRole
  staffRoleAssignments: StaffRoleAssignment[]
}

export interface GetUrbanXTeamByAgenciesRequest extends ApiRequestBody {
  role: string
  agencyIds: string[]
}

const GetStaffAssignedToAgencies = async (
    req: GetUrbanXTeamByAgenciesRequest,
): Promise<StaffRoleAssignmentsReponse | undefined> => {  
  const token = await req.getAuthToken()
  if (!token || !req.role || !req.agencyIds) return

  const service = new AgencyService(token)

  if (!service) return  

  const result = await service.agencyService.get(
    'GetStaffMembersAssignedToAgencies', { role: req.role, agencyIds: req.agencyIds })

  return result.data || []
}

export default GetStaffAssignedToAgencies