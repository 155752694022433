import { useEffect, useState } from 'react'
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react'
import { Outlet } from 'react-router-dom'
import { InteractionStatus } from '@azure/msal-browser'
import usePrevious from '../hooks/usePrevious'
import { useAzureAuth } from '../hooks/useAzureAuth'
import { UserContext } from '../contexts'
import { User } from '../types/user'
import { SetupNotificationsLayout } from './SetupNotificationsLayout'
import LogRocket from 'logrocket'

export const AuthenticatedLayout = () => {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const previousInProgress = usePrevious(inProgress)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [userAccount, getAuthToken] = useAzureAuth()

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return
    if (previousInProgress === InteractionStatus.HandleRedirect) return
    getAuthToken()
  }, [instance, userAccount, isAuthenticated, inProgress])

  useEffect(() => {
    if (!userAccount) return

    const user = userAccount as User

    setUser({
      ...user,
      firstName: user.firstName,
      agent: user.agent,
    })

    LogRocket.identify(user.agent || '', {
      name: user.fullName || '',
      email: user.agent || '',
      agency: user.agency || '',
    })
  }, [userAccount])

  if (!user) return <AuthenticatedTemplate />

  return (
    <UserContext.Provider value={user}>
      {isAuthenticated && (
        <SetupNotificationsLayout>
          <Outlet />
        </SetupNotificationsLayout>
      )}
    </UserContext.Provider>
  )
}
