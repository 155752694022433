import AgencyService from '../../agenciesService'

interface SendInvitationEmailRequest {
  agentId: string
  getAuthToken?: () => Promise<string | void>
}

const SendInvitationEmail = async (
  requestBody: SendInvitationEmailRequest
): Promise<number | void> => {
  const { agentId, getAuthToken } = requestBody
  if (!getAuthToken) {
    return
  }
  const token = await getAuthToken()

  const service = new AgencyService(token || '')

  if (!service) return

  const result = await service.agencyService.post('SendInvitationEmail', {
    AgentId: agentId,
  })

  return result.status
}

export default SendInvitationEmail
