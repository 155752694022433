import { ExecutiveStatistics } from 'types/executiveStatistics';
import AgencyService from 'services/agenciesService';

const getExecutiveStatistics = async (
  getAuthToken: () => Promise<string | void>,
): Promise<ExecutiveStatistics[] | undefined> => {
    const token = await getAuthToken()
    if (!token) return undefined;

    const service = new AgencyService(token);
    
    if (!service) return undefined;

    const result = await service.agencyService.get('GetExecutiveKeyMetrics');
    return result.data || [];
}

export default getExecutiveStatistics;