import React, { useEffect, useState } from 'react'
import { useAzureAuth } from '../hooks/useAzureAuth'
import { NotificationContext } from '../contexts'
import { ConnectionState, Notification, NotificationEvents } from '../types/notification'
import { getConnection, receiveNotifications, stopNotifications } from '../utils/notifications'
import { HubConnection } from '@microsoft/signalr'
import { AppAlert } from '../components/app-alert/app-alert'

interface Props {
  children: JSX.Element
}

const defaultNotification: Notification = {
  connectionState: ConnectionState.NotConnected,
  connectionUpdatedAt: new Date(),
  registerEvent: null,
  unregisterEvent: null,
}

export const SetupNotificationsLayout: React.FC<Props> = ({ children }: Props) => {
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(null)
  const [userAccount, getAuthToken] = useAzureAuth()
  const [notification, setNotification] = useState<Notification>(defaultNotification)
  const [lockResolver, setLockResolver] = useState<((value?: unknown) => void) | null>(null)

  // Add a lock to prevent the browser tab from sleeping while it isn't opened
  useEffect(() => {
    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise(res => {
        setLockResolver(res);
      });

      navigator.locks.request(
        'autopilot_shared_lock',
        {
          mode: 'shared',
        },
        () => {
          return promise;
        }
      );
    }

    return () => {
      if (lockResolver) {
        lockResolver();
      }
    };
  }, []);

  useEffect(() => {
    if (hubConnection || !userAccount) return

    setNotification({
      ...notification,
      connectionState: ConnectionState.Connecting,
      connectionUpdatedAt: new Date(),
    })

    const hubCon = getConnection("campaigns", getAuthToken, () => {
      setNotification({
        ...notification,
        connectionState: ConnectionState.Reconnecting,
        connectionUpdatedAt: new Date(),
      })
    })

    hubCon.onclose(() => {
      setNotification({
        ...notification,
        connectionState: ConnectionState.Disconnected,
        connectionUpdatedAt: new Date(),
      })
    })

    hubCon.onreconnecting(() => {
      setNotification({
        ...notification,
        connectionState: ConnectionState.Reconnecting,
        connectionUpdatedAt: new Date(),
      })
    })

    hubCon.onreconnected(() => {
      setNotification({
        ...notification,
        connectionState: ConnectionState.Connected,
        connectionUpdatedAt: new Date(),
      })
    })

    hubCon
      .start()
      .then(() => {
        setNotification({
          ...notification,
          connectionState: ConnectionState.Connected,
          connectionUpdatedAt: new Date(),
          registerEvent: (eventType: string, eventCallback: (event: NotificationEvents) => void) => {
            receiveNotifications(hubCon, eventType, eventCallback)
          },
          unregisterEvent: (eventType: string) => {
            stopNotifications(hubCon, eventType)
          }
        })
      })

    setHubConnection(hubCon)
  }, [userAccount, hubConnection])

  let appAlert = null

  if (notification.connectionState === ConnectionState.Reconnecting) {
    appAlert = (
      <AppAlert text="Staff Portal is reconnecting..." />
    )
  } else if (notification.connectionState === ConnectionState.Disconnected) {
    appAlert = (
      <AppAlert text="Staff Portal disconnected. Try reload the web page." />
    )
  }

  return (
    <NotificationContext.Provider value={notification}>
      {appAlert}
      {children}
    </NotificationContext.Provider>
  )
}