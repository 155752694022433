import { RunRateInformation } from 'types/executiveStatistics';
import AgencyService from 'services/agenciesService';

const getRunRateStatistics = async (
  getAuthToken: () => Promise<string | void>,
): Promise<RunRateInformation[] | undefined> => {
    const token = await getAuthToken()
    if (!token) return undefined;

    const service = new AgencyService(token);
    
    if (!service) return undefined;

    const result = await service.agencyService.get('GetExecutiveRunRateMetrics');
    return result.data || [];
}

export default getRunRateStatistics;