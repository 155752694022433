import React from 'react'
import './app-alert.scss'

interface Props {
  text: string
}

export const AppAlert: React.FC<Props> = ({ text }: Props) => {
  return (
    <div className="agx-alert" role="alert">
      <span>{text}</span>
    </div>
  )
}