import AgencyService from '../../agenciesService'
import { Name } from '@urbanx/agx-ui-components'

export interface AgentDetailsProjection {
  id: string
  agencyId: string
  name: Name
  photoUri?: string
}

const staffGetAllAgents = async (
  getAuthToken: () => Promise<string | void>
): Promise<AgentDetailsProjection[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get('StaffGetAllAgents')

  return result.data || []
}

export default staffGetAllAgents
