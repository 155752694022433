import { CampaignDetailModel } from '@urbanx/agx-ui-components'
import CampaignsService from 'services/campaignsService'

const getCampaignById = async (
  campaignId: string,
  getAuthToken: () => Promise<string | void>
): Promise<CampaignDetailModel | null> => {
  const token = await getAuthToken()
  if (!token) return null

  const service = new CampaignsService(token)
  if (!service) return null

  const result = await service.campaignsService.get('StaffGetCampaign', {
    CampaignId: campaignId,
  })

  return result.data
}

export default getCampaignById
