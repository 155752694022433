import StaffService from '../../staffService'
import { Staff } from '../../../types/staff'

const GetAllStaff = async (
  getAuthToken: () => Promise<string | void>
): Promise<Staff[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new StaffService(token)

  if (!service) return

  const result = await service.staffService.get('GetAllStaff')

  return result.data || []
}

export default GetAllStaff
