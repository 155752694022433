import { AgxHeader } from '@urbanx/agx-ui-components'
import React from 'react'
import './reporting.scss'

interface KeyMetricsButtonProps {
  setTableMetrics: (metric: string) => void
  isActive: boolean
}

const KeyMetricsButton: React.FC<KeyMetricsButtonProps> = ({
  setTableMetrics,
  isActive,
}) => {
  const buttonStyle = isActive ? 'activeHeader' : 'inactiveHeader'
  return (
    <div
      className={`key-metrics-header ${buttonStyle}`}
      onClick={() => setTableMetrics('keyMetrics')}
    >
      <AgxHeader size={5}>Key Metrics</AgxHeader>
    </div>
  )
}

export default KeyMetricsButton
