import { QueryKey } from '@tanstack/query-core'
import AgencyService from '../../agenciesService'
import { PartnershipDetails } from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types'

const staffGetPartnershipDetailsForAgency = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<PartnershipDetails | undefined | null> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agencyId = queryKey[1] as string

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get(
    'StaffGetPartnershipDetailsForAgency',
    {
      agencyId,
    }
  )

  return result.data || undefined
}

export default staffGetPartnershipDetailsForAgency
