import {
  AgxOwnerBuilderDeclaration,
  AgxEditablePdfUpload,
  AgxSelect,
  PropertyAddress,
  DisplayElement,
  ElementType,
  AgxConditionalTextInput,
  AgxSolicitorSearch,
  AgxVendorBuyerDetails,
  AgxYesNoQuestion,
  Solicitor,
  VendorBuyer,
  AgxFileUpload,
  AgxTenantedPropertyDetails,
  TenantedProperty,
  Tenancy,
  AgxPropertyAddressForm,
  AgxTextInput,
  AgxPropertyAttributes,
  AgxLegalDescription,
  DocumentTypes,
  AgxTenancies,
  FormType,
  AgxCheckbox,
  PropertyAttribute,
  LegalDescriptionDisplayElement,
  LegalDescription,
  AgxCurrency,
  EditScope,
  StakeholderType,
  conditionIsMet,
  AgxChattels,
  ChattelsIncluded,
  Option,
  OwnerBuilderInformation,
  EditablePdfUpload,
  FileUpload,
  Dropdown,
  VendorBuyerDetails,
  ConditionalTextInput,
  TypedDocumentUpload,
  ContractType,
  Currency,
  AddressSearchElement,
  AgxCustomCondition,
  DefaultPreDefinedSpecialConditions,
  SpecialConditions,
  AgxSpecialCondition,
  formattedVendorNames,
  CampaignDetailModel,
} from '@urbanx/agx-ui-components'
import { FormValue, FormValueType } from 'types/formConfig'
import {
  deleteFile,
  getFileLink,
  uploadFile,
  editFile,
  GetPdfLicenseKey,
} from 'services'
import { YesNo } from 'types/displayElements'

interface Props {
  elements: DisplayElement[]
  campaign: CampaignDetailModel
  getAuthToken: () => Promise<string | void>
  onChange: (change: {
    id: string
    value: FormValueType[keyof FormValueType]
  }) => void
  formValues: FormValue
  validate: boolean
}

export default function elementRenderer(props: Props) {
  const { elements, onChange, campaign, getAuthToken, formValues, validate } =
    props

  if (!elements) return

  const onAddressSelected = (
    id: string,
    address: PropertyAddress | undefined
  ) => {
    onChange({
      id,
      value: address as PropertyAddress,
    })
  }

  const renderElement = (element: DisplayElement) => {
    const currentValue = element.value
    const required =
      conditionIsMet(element.requiredCondition, formValues) ||
      (element.required ?? false)
    const disabled =
      conditionIsMet(element.disableCondition, formValues) ||
      (element.disabled ?? false)

    const preDefinedConditions = formValues?.PredefinedSpecialConditions

    switch (element.elementType) {
      case ElementType.Dropdown: {
        const dropdownOptions = (element as Dropdown).options
        const dropdownValue =
          dropdownOptions.find((option) => option.value === currentValue) ??
          dropdownOptions[0]

        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxSelect
              id={element.id}
              key={`${element.id}`}
              onValueChanged={onChange}
              defaultValue={dropdownValue}
              label={element.label ?? ''}
              hideOptionalLabel={!required}
              options={dropdownOptions}
              required={required}
            />
          </div>
        )
      }

      case ElementType.YesNoQuestion: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxYesNoQuestion
              id={element.id}
              key={`${element.id}`}
              onValueChanged={onChange}
              defaultValue={currentValue}
              label={element.label ?? ''}
              subDescription={element.subDescription ?? ''}
              yesLabel={(element as YesNo).yesLabel}
              noLabel={(element as YesNo).noLabel}
              reverseLabelOrder={(element as YesNo).reverseLabelOrder}
              required={required}
              validate={validate}
            />
          </div>
        )
      }

      case ElementType.VendorBuyerDetails: {
        const vendorElement = element as VendorBuyerDetails
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxVendorBuyerDetails
              id={element.id}
              key={`${element.id}`}
              getAuthToken={getAuthToken}
              uploadFile={uploadFile}
              deleteFile={deleteFile}
              getFileLink={getFileLink}
              onValueChanged={onChange}
              propertyAddress={campaign.address}
              defaultValue={currentValue as VendorBuyer[]}
              editScope={vendorElement.editScope ?? EditScope.PartialEdit}
              stakeholderType={
                vendorElement.stakeholderType ?? StakeholderType.Buyer
              }
              vendorBuyerTypes={vendorElement.vendorBuyerTypes}
              vendorBuyerConfig={vendorElement.vendorBuyerConfig}
              powerOfAttorneyConfig={vendorElement.powerOfAttorneyConfig}
              companyConfig={vendorElement.companyConfig}
              required={required}
              campaignId={campaign.campaignId}
              selectedFormType={FormType.BlankContract}
              //TODO: The component is going to support optional "agents" and "leadAgentId" props soon, remvoe these declarations after the update.
              agents={[] as Option[]}
              leadAgentId={''}
              validate={validate}
            />
          </div>
        )
      }

      case ElementType.SolicitorSearch: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxSolicitorSearch
              id={element.id}
              key={`${element.id}`}
              onValueUpdated={onChange}
              defaultValue={currentValue as Solicitor}
              label={element.label ?? ''}
              solicitorLabel={element.solicitorLabel}
              required={element.required}
              validate={validate}
            />
          </div>
        )
      }
      case ElementType.TenancyDetails: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxTenancies
              id={element.id}
              key={`${element.id}`}
              onValueChanged={onChange}
              defaultTenancies={currentValue as Tenancy[]}
              validate={validate}
            />
          </div>
        )
      }
      case ElementType.AddressSearch: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxPropertyAddressForm
              key={element.id}
              label={element.label ?? ''}
              onAddressSelected={(address) =>
                onAddressSelected(element.id, address)
              }
              defaultValue={currentValue as PropertyAddress}
              propertyAddressField={false}
              showSearchForAddress={false}
              hideUnitLot={
                formValues?.ContractType !== ContractType.CommunityTitles
              }
              required={required}
              validate={validate}
              stateToSearch={
                (element as AddressSearchElement).stateToSearch ?? undefined
              }
            />
          </div>
        )
      }

      case ElementType.TextInput: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxTextInput
              id={element.id}
              key={`${element.id}`}
              onInputValueChange={onChange}
              label={element.label ?? ''}
              stretch
              noOptionalLabel={required}
              defaultValue={(currentValue as string) ?? ''}
              required={required}
              disabled={disabled}
              placeholder={element.placeholder ?? ''}
              readonly={element.isReadonly}
              subDescription={element.subDescription ?? ''}
              rightAlignedPlaceholder={element.rightAlignedPlaceholder}
            />
          </div>
        )
      }

      case ElementType.PropertyAttributes: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxPropertyAttributes
              id={element.id}
              key={`${element.id}`}
              onChange={onChange}
              propertyAttributes={currentValue as PropertyAttribute}
            />
          </div>
        )
      }

      case ElementType.LegalDescription: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}$`}>
            <AgxLegalDescription
              id={element.id}
              key={`${element.id}`}
              hidePropertyType={
                (element as LegalDescriptionDisplayElement).hidePropertyType
              }
              readonly={element.isReadonly}
              onChange={onChange}
              legalDescription={currentValue as LegalDescription}
              required={required}
              validate={validate}
            />
          </div>
        )
      }

      case ElementType.ConditionalTextInput: {
        const conditionalTextInput = element as ConditionalTextInput

        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxConditionalTextInput
              id={element.id}
              key={`${element.id}`}
              onValueChanged={onChange}
              defaultValue={currentValue as string}
              label={element.label ?? ''}
              maxLength={conditionalTextInput.maxLength}
              minLength={conditionalTextInput.minLength ?? 0}
              inputType={conditionalTextInput.inputType}
              noLabel={conditionalTextInput.noLabel ?? 'No'}
              yesLabel={conditionalTextInput.yesLabel ?? 'Yes'}
              reverseLabelOrder={conditionalTextInput.reverseLabelOrder}
              showTextInputOnYesSelection={
                conditionalTextInput.showTextInputOnYesSelection
              }
              noTextInputValue={conditionalTextInput.noTextInputValue}
              placeholder={element.placeholder ?? ''}
              subDescription={element.subDescription ?? ''}
              disabled={disabled}
              readonly={element.isReadonly}
              required={required}
              flexibleHeight={element.flexibleHeight}
              validate={validate}
            />
          </div>
        )
      }

      case ElementType.FileUpload: {
        const fileUploadElement = element as FileUpload
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxFileUpload
              id={element.id}
              key={`${element.id}`}
              fileData={currentValue}
              campaignId={campaign.campaignId}
              documentType={
                fileUploadElement.documentType ?? DocumentTypes.Miscellaneous
              }
              onValueChanged={onChange}
              multiple={fileUploadElement.multiple ?? false}
              getAuthToken={getAuthToken}
              extensions={fileUploadElement.extensions ?? []}
              uploadFile={uploadFile}
              deleteFile={deleteFile}
              getFileLink={getFileLink}
              required={required}
              readonly={element.isReadonly ?? false}
              title={element.label ?? ''}
              validate={validate}
            />
          </div>
        )
      }

      case ElementType.EditablePdfUpload: {
        const editablePdfUpload = element as EditablePdfUpload
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxEditablePdfUpload
              id={element.id}
              key={`${element.id}`}
              fileData={currentValue}
              campaignId={campaign.campaignId}
              documentType={
                editablePdfUpload.documentType ?? DocumentTypes.Miscellaneous
              }
              buttonGroups={editablePdfUpload.buttonGroups}
              getLicenseKey={GetPdfLicenseKey}
              onValueChanged={onChange}
              multiple={editablePdfUpload.multiple ?? false}
              getAuthToken={getAuthToken}
              uploadFile={uploadFile}
              editFile={editFile}
              deleteFile={deleteFile}
              getFileLink={getFileLink}
              required={required}
              readonly={element.isReadonly ?? false}
              title={element.label ?? ''}
              validate={validate}
            />
          </div>
        )
      }

      case ElementType.TypedDocumentUpload:
        return (element as TypedDocumentUpload).documentConfig?.map(
          (config) => (
            <div
              className="panelTitleContainer"
              key={`container-${element.id}`}
            >
              <AgxFileUpload
                id={element.id}
                key={`${element.id}`}
                fileData={currentValue}
                campaignId={campaign.campaignId}
                documentType={
                  config?.documentType ?? DocumentTypes.Miscellaneous
                }
                onValueChanged={onChange}
                multiple={config?.multipleFiles ?? false}
                getAuthToken={getAuthToken}
                extensions={[]}
                uploadFile={uploadFile}
                deleteFile={deleteFile}
                getFileLink={getFileLink}
                required={config?.required ?? required}
                readonly={config?.readonly ?? false}
                validate={validate}
              />
            </div>
          )
        )

      case ElementType.Checkbox:
        return (
          <div
            className="panelTitleContainer checkboxContainer"
            key={`container-${element.id}`}
          >
            <AgxCheckbox
              id={element.id}
              key={`${element.id}`}
              onValueChanged={onChange}
              label={element.label}
              stretch
              defaultValue={currentValue as boolean | undefined}
              disabled={disabled}
              subLabel={element.subLabel}
            />
          </div>
        )

      case ElementType.TenantedProperty: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxTenantedPropertyDetails
              id={element.id}
              key={`${element.id}`}
              onValueUpdated={onChange}
              defaultValue={currentValue as TenantedProperty}
              managingAgentOptional={false}
              label={element.label ?? ''}
              validate={validate}
              showHolidayRentalOption={element.showHolidayRental}
              showPreviousTenancyDetails={!!element.showPreviousTenancyDetails}
            />
          </div>
        )
      }

      case ElementType.Currency:
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxCurrency
              id={element.id}
              key={`${element.id}`}
              label={element.label}
              placeholder={element.placeholder ?? ''}
              required={required}
              onInputValueChange={onChange}
              readonly={element.isReadonly}
              disabled={disabled}
              stretch
              defaultValue={currentValue as string}
              decimalPoints={(element as Currency).decimalPoints ?? 0}
              subDescription={element.subDescription ?? ''}
              validate={validate}
            />
          </div>
        )

      case ElementType.PredefinedSpecialConditions: {
        const specialConditionElement = element
        return (
          <AgxSpecialCondition
            id={specialConditionElement.id}
            key={`${specialConditionElement.id}`}
            defaultValue={
              specialConditionElement.value as DefaultPreDefinedSpecialConditions
            }
            onValueChange={onChange}
            stateToSearch={null}
            vendors={formattedVendorNames(formValues.Vendors as VendorBuyer[])}
            validate={validate}
          />
        )
      }

      case ElementType.SpecialConditions: {
        return (
          <div className="panelTitleContainer" key={`container-${element.id}`}>
            <AgxCustomCondition
              id={element.id}
              key={`${element.id}`}
              predefinedConditions={
                preDefinedConditions as DefaultPreDefinedSpecialConditions
              }
              onValueChange={onChange}
              validate={validate}
              defaultValue={currentValue}
              disableSubPoints={
                (element as SpecialConditions).disableSubPoints ?? false
              }
              backgroundColor={
                (element as SpecialConditions).backgroundColor ?? false
              }
              label={element.label ?? ''}
              subLabel={element.subLabel ?? ''}
            />
          </div>
        )
      }

      case ElementType.ChattelsIncluded: {
        return (
          <div className="panelTitleContainer">
            <AgxChattels
              label={element.label ?? ''}
              id={element.id}
              defaultValue={element.value as ChattelsIncluded}
              state={campaign?.address?.state}
              onValueChange={onChange}
              validate={validate}
              flexibleHeight={element.flexibleHeight}
            />
          </div>
        )
      }

      case ElementType.OwnerBuilderDeclaration: {
        const editablePdfUpload = element as EditablePdfUpload
        return (
          <div className="panelTitleContainer">
            <AgxOwnerBuilderDeclaration
              id={element.id}
              onChange={onChange}
              getAuthToken={getAuthToken}
              campaignId={campaign.campaignId}
              documentType={editablePdfUpload.documentType}
              buttonGroups={editablePdfUpload.buttonGroups}
              defaultValue={element.value as OwnerBuilderInformation}
              editFile={editFile}
              uploadFile={uploadFile}
              deleteFile={deleteFile}
              getFileLink={getFileLink}
              getLicenseKey={GetPdfLicenseKey}
              required={element.required}
              validate={validate}
            />
          </div>
        )
      }

      default:
        return null
    }
  }

  return <>{elements.map(renderElement)}</>
}
