import { AgxRow, AgxColumn, AgxBodyText, Images, UploadedDocument } from '@urbanx/agx-ui-components';
import { formatFileSize } from '../../../../utils/formatFileSize';

interface Props {
    document: UploadedDocument;
    downloadDocument: (document: UploadedDocument) => void;
}
const DocumentRow = (props: Props) => {
    const { document, downloadDocument } = props

    return (

        <AgxRow key={document.documentId}>
            <AgxColumn extraClasses='file-document'>
                <div className="file-document-container" onClick={() => downloadDocument(document)}>
                    <Images.FileTextOutline className="file-document-leftIcon" />
                    <AgxBodyText medium extraClasses="file-document-body">
                        <span className="file-document-name">{document.fileName}</span>
                        <span className="file-document-size">{formatFileSize(document.fileSizeInBytes)}</span>
                    </AgxBodyText>
                    <Images.Download className="file-document-download" />
                </div>
            </AgxColumn>
            <AgxColumn extraClasses='file-document-date'>
                <AgxBodyText>
                    <span>
                        {document.dateUploaded ? new Date(document.dateUploaded).toLocaleString('en-US') : ''}
                    </span>
                </AgxBodyText>
            </AgxColumn>
        </AgxRow>
    )
};

export default DocumentRow;