import moment from 'moment'

export function formatDateRange(date1Str: string, date2Str: string): string {
  const date1 = moment(date1Str)
  const date2 = moment(date2Str)

  const start = date1 < date2 ? date1 : date2
  const end = date1 >= date2 ? date1 : date2

  if (start.format('YYYY-MM') === end.format('YYYY-MM')) {
    // Same month and year
    return `${start.format('MMMM D')}-${end.format('D YYYY')}`
  } else if (start.format('YYYY') === end.format('YYYY')) {
    // Same year, different month
    return `${start.format('MMMM D')}-${end.format('MMMM D YYYY')}`
  } else {
    // Different month, different year
    return `${start.format('MMMM D YYYY')}-${end.format('MMMM D YYYY')}`
  }
}
