import {
  CampaignCompletionStateType,
  CampaignStage,
  PropertyAddress,
  PropertyImageUrls,
  UploadedDocument,
} from '@urbanx/agx-ui-components'

export enum CampaignTableColumn {
  Campaign = 'address',
  Status = 'status',
  LeadAgent = 'leadAgentId',
  SecondAgent = 'secondAgentId',
  BlankContract = 'blankSalesContract',
}

export enum CampaignTableColumnNames {
  Campaign = 'Campaign',
  Status = 'Status',
  LeadAgent = 'Lead Agent',
  SecondAgent = 'Second Agent',
  BlankContract = 'Blank Contract',
}

export enum CampaignColumnStatus {
  Agreement = 'Agreement',
  CurrentListing = 'Current Listing',
  UnderContract = 'Under Contract',
  Live = 'Live',
  Offline = 'Offline',
}

export enum CampaignsTab {
  Campaigns = 'Campaigns',
  Archived = 'Archived',
}

export interface CampaignCompletionState {
  completionType: CampaignCompletionStateType | null
  completionDate: Date | null
}

export interface Campaign {
  id: string
  address: PropertyAddress
  createdByUserId: string
  propertyImageUrls: PropertyImageUrls
  leadAgentId: string
  secondAgentId: string
  stage: CampaignStage
  blankContractIsLive: boolean
  completionState: CampaignCompletionState
  assignedAgentIds: string[]
  blankSalesContract: UploadedDocument | null
  blankSalesContractFlattened: UploadedDocument | null
}

export interface CampaignRequest {
  getAuthToken: () => Promise<string | void>
  campaignId: string
}

export interface Campaigns {
  data: Campaign[]
  pageIndex: number
  pageSize: number
  totalResults: number
}

export interface CampaignOwnership {
  campaignId: string
  leadAgentId: string
  secondAgentId: string
  assignedAgents: string[]
}

export enum FormDocumentSubmissionType {
  Manual = 'Manual',
  DocuSign = 'DocuSign',
}

export enum FormDocumentSubmissionStatus {
  Created = 'Created',
  Sent = 'Sent',
  Opened = 'Opened',
  Signed = 'Signed',
  Complete = 'Complete',
  Declined = 'Declined',
  Bounced = 'Bounced',
}

export interface FormDocumentSubmissionRecipient {
  recipientId: string
  emailAddress: string
  name: string
  sentDateTime: Date | null
  deliveredDateTime: Date | null
  signedDateTime: Date | null
  status: FormDocumentSubmissionStatus
}

export interface FormDocumentSubmission {
  formDocumentSubmissionId: string
  type: FormDocumentSubmissionType
  status: FormDocumentSubmissionStatus
  recipients: FormDocumentSubmissionRecipient[]
  createdAt: Date
  updatedAt: Date | null
}

export interface FormDocumentSubmissionDetails {
  agencyAgreementSubmission: FormDocumentSubmission | null
}

export enum CampaignStageAction {
  Restore = 'Restore',
  RevertToCurrent = 'RevertToCurrent',
  MarkAsUnderContract = 'MarkAsUnderContract',
  MarkAsSold = 'MarkAsSold',
  Archive = 'Archive',
  Withdraw = 'Withdraw',
}
