import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import RealestateService from '../../realestateService'

interface UpdateRealestateCodeRequest extends ApiRequestBody {
  AgencyId?: string
  Code?: string | null
}

const UpdateRealestateCode = async (
  requestBody: UpdateRealestateCodeRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new RealestateService(token)

  if (!service) return

  await service.realestateService.post('SetAgencyCode', {
    AgencyId: requestBody.AgencyId,
    Code: requestBody.Code,
  })
}

export default UpdateRealestateCode
