import React, { useMemo } from 'react'
import './reporting.scss'
import { AgxSelect } from '@urbanx/agx-ui-components'

const YearMonthDropdown: React.FC<{
  selectedMonth: string
  setSelectedMonth: (month: string) => void
}> = ({ selectedMonth, setSelectedMonth }) => {
  const months = useMemo(() => {
    const result: string[] = []
    const currentDate = new Date()

    for (let i = 11; i >= 0; i--) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      )
      result.unshift(
        date.toLocaleString('default', { month: 'long', year: 'numeric' })
      )
    }

    return result.map((r) => new Option(r, r))
  }, [])

  return (
    <AgxSelect
      id={'yearMonthDropdown'}
      options={months}
      onValueChanged={({ value }) => setSelectedMonth(value)}
      defaultValue={months.find((m) => m.value === selectedMonth) ?? months[0]}
      required
      extraClasses={'reporting-year-month-dropdown'}
    />
  )
}
export default YearMonthDropdown
