import { StaffApi } from '../helpers/http'

class AgencyService {
  authToken: string
  agencyService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.agencyService = new StaffApi('agencies', authToken)
  }
}

export default AgencyService