import { useContext, useEffect, useMemo, useState } from 'react'
import { MenuTitles } from '../../constants/menu'
import { AgencyContext, UserContext } from 'contexts'
import { ContentLayout, PageLayout } from 'layout'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEqualWith from 'lodash/isEqualWith'
import moment from 'moment'
import {
  AgxTextInput,
  AgxDatePicker,
  AgxColumn,
  AgxRow,
  AgxLabel,
  AgxButton,
  AgxToast,
  AgxSelect,
  AgxTextArea,
  AustralianStateNumericMap,
  AustralianState,
  AgxMultiSelect,
  Option,
  StateAgreementFormTypeMap,
  StateAgreementFormType,
  AgxPhoneNumberInput,
} from '@urbanx/agx-ui-components'
import { Agency, AgencyDetails } from 'types/agency'
import { GetAllAgencies } from 'services'
import { GetAgencyDetails, UpdateAgency } from 'services'
import { AgxToastState } from 'types/commonTypes'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import { isDirtyCompare } from 'utils/compares'
import './licenseeDetails.css'

const agencyStateOptions = [
  { value: AustralianStateNumericMap.ACT.toString(), label: 'ACT' },
  { value: AustralianStateNumericMap.NSW.toString(), label: 'NSW' },
  { value: AustralianStateNumericMap.QLD.toString(), label: 'QLD' },
  { value: AustralianStateNumericMap.Tas.toString(), label: 'TAS' },
  { value: AustralianStateNumericMap.Vic.toString(), label: 'VIC' },
  { value: AustralianStateNumericMap.WA.toString(), label: 'WA' },
]

const allStateOptions: Option[] = Object.values(AustralianState).map(
  (state) => {
    return {
      value: state.toString(),
      label: state.toString(),
    }
  }
)

const qldAgreementOptions: Option[] = [
  {
    value: StateAgreementFormType.QLDResidentialForm6.toString(),
    label: StateAgreementFormTypeMap.QLDResidentialForm6,
  },
]

const nswAgreementOptions: Option[] = [
  {
    value: StateAgreementFormType.NSWResidentialAgreement.toString(),
    label: StateAgreementFormTypeMap.NSWResidentialAgreement,
  },
  {
    value: StateAgreementFormType.NSWRuralAgreement.toString(),
    label: StateAgreementFormTypeMap.NSWRuralAgreement,
  },
]

const getAgreementOptions = (companyState: string | number) => {
  switch (companyState) {
    case AustralianStateNumericMap.QLD.toString():
    case AustralianStateNumericMap.QLD: {
      return qldAgreementOptions
    }
    case AustralianStateNumericMap.NSW.toString():
    case AustralianStateNumericMap.NSW: {
      return nswAgreementOptions
    }
    default:
      return []
  }
}

const LicenseeDetailsPage = () => {
  const user = useContext(UserContext)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [formLoaded, setFormLoaded] = useState(false)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const { agencyId } = useParams()
  const queryClient = useQueryClient()

  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const [, getAuthToken] = useAzureAuth()

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const [licenseeInformation, setLicenseeInformation] = useState<AgencyDetails>(
    new AgencyDetails()
  )
  const [agreementOptions, setAgreementOptions] = useState<Option[]>([])

  const {
    data: agencyDetails,
    isFetched,
    isLoading,
  } = useQuery<AgencyDetails | undefined>({
    queryKey: ['getAgencyDetails', agencyId],
    queryFn: (queryKey) => GetAgencyDetails(queryKey, getAuthToken),
    enabled: !!agencyId,
  })

  const { mutate: updateAgencyDetails } = useMutation(UpdateAgency, {
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: ['getAgencyDetails', agencyId],
      })
      updateToastState({
        color: 'success',
        message: 'Licensee Details updated successfully',
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error updating Licensee Details',
        open: true,
      })
    },
  })

  useEffect(() => {
    if (agencyId && selectedAgency?.id !== agencyId) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies, agencyId])

  useEffect(() => {
    if (isFetched && agencyDetails) {
      setLicenseeInformation(agencyDetails)
      setIsFormDirty(false)

      if (agencyDetails.id != null) setFormLoaded(true)
    }
  }, [agencyDetails])

  useEffect(() => {
    if (licenseeInformation?.companyDetails?.state) {
      setAgreementOptions(
        getAgreementOptions(licenseeInformation?.companyDetails?.state)
      )
    }
  }, [licenseeInformation?.companyDetails?.state])

  useEffect(() => {
    setIsFormDirty(
      !isEqualWith(agencyDetails, licenseeInformation, isDirtyCompare)
    )
  }, [licenseeInformation, agencyDetails])

  const addressStateDefaultValue = useMemo(() => {
    const selectedOption = allStateOptions.find(
      (option) => option.value === licenseeInformation?.address?.state
    )
    return selectedOption ?? allStateOptions[0]
  }, [licenseeInformation?.address?.state])

  if (licenseeInformation == null) return null

  const {
    tradingName,
    companyDetails: {
      licenseeName,
      abn,
      acn,
      licenseNumber,
      expiryDate,
      state,
    } = {},
    listingsEmail,
    listingsPhone,
    address: { streetAddress, suburb, postalCode } = {},
    instructionsConditions,
  } = licenseeInformation

  const saveChanges = () => {
    updateAgencyDetails({
      getAuthToken,
      ...licenseeInformation,
    })
  }

  if (!isFetched || isLoading || !formLoaded) return null

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Licensee Details"
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.LICENSEE_DETAILS}
      >
        <FormPrompt hasUnsavedChanges={isFormDirty} />
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween centered extraClasses="borderBottomContainer">
            <AgxLabel large>Company details</AgxLabel>
            <AgxButton
              text="Save Changes"
              medium
              primary
              onClick={saveChanges}
            />
          </AgxRow>

          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow>
              <AgxTextInput
                id="tradingName"
                label="Trading Name"
                stretch
                noOptionalLabel
                defaultValue={tradingName || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    tradingName: value,
                  }))
                }
              />
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="licenseeName"
                label="Licensee name"
                stretch
                noOptionalLabel
                defaultValue={licenseeName || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      licenseeName: value,
                    },
                  }))
                }
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="abn"
                label="ABN"
                abn={true}
                stretch
                noOptionalLabel
                defaultValue={abn || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      abn: value,
                    },
                  }))
                }
              />

              <AgxTextInput
                id="acn"
                label="ACN"
                acn={true}
                stretch
                noOptionalLabel
                defaultValue={acn || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      acn: value,
                    },
                  }))
                }
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="licenseNumber"
                label="License Number"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={licenseNumber || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      licenseNumber: value,
                    },
                  }))
                }
              />

              <AgxDatePicker
                id="dtLicenseeExpirtDate"
                date
                label="Expiry Date"
                noOptionalLabel
                stretch
                defaultValue={expiryDate}
                onValueChanged={({ value }: { value: string }) => {
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      expiryDate:
                        value === 'Invalid date' || value === ''
                          ? null
                          : moment(value).format('YYYY-MM-DDTHH:mm:ss'),
                    },
                  }))
                }}
              />
            </AgxRow>
            <AgxRow veryLargeGap extraClasses="checkboxContainer">
              {/*
               TODO: Enable Multi-select state dropdown when we have to introduce the functionality to the agents
               As per the new requirements in the card https://urbanx-development.atlassian.net/browse/AG-2272
               <AgxMultiSelect
                 id="companyState"
                 label="State"
                 hideOptionalLabel
                 defaultValue={companyStates}
                 onValueChanged={({ value }: { value: string }) => {
                   setCompanyStates(!value ? undefined : value?.split(','))
                 }}
                 options={stateOptions}
               /> */}
              <AgxSelect
                id="companyState"
                label="State"
                extraClasses="companyState"
                options={agencyStateOptions}
                defaultValue={{
                  label:
                    (state?.toString() && AustralianStateNumericMap[state]) ??
                    '',
                  value: state?.toString() ?? '',
                }}
                hideOptionalLabel
                onValueChanged={({ value }: { value: string }) => {
                  value
                    ?.split(',')
                    .map((v) => {
                      const valueAsNumber = new Number(v)
                      if (!Number.isNaN(valueAsNumber)) {
                        return valueAsNumber.valueOf()
                      }
                    })
                    .filter((v) => v !== undefined) as number[]
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      state: !value
                        ? Number.parseInt(agencyStateOptions[0].value)
                        : Number.parseInt(value),
                    },
                  }))
                  setSelectedAgency(
                    () =>
                      ({
                        ...selectedAgency,
                        state: Number.parseInt(value),
                      } as Agency)
                  )
                }}
              />
              <AgxMultiSelect
                id="agreements"
                label="Agreements"
                options={agreementOptions}
                defaultValue={agreementOptions?.reduce(
                  (acc: string[], curr: Option) => {
                    const ag = agencyDetails?.companyDetails?.agreements?.find(
                      (t: any) => t === Number(curr.value)
                    )
                    if (ag !== undefined) {
                      acc.push(ag.toString())
                    }
                    return acc
                  },
                  []
                )}
                hideOptionalLabel
                onValueChanged={({ value }: { value: string[] }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      agreements: value?.map((v) => parseInt(v)) ?? [],
                    },
                  }))
                }
              />
            </AgxRow>
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Details</AgxLabel>
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="listingsEmail"
                label="Listings Email"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={listingsEmail || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    listingsEmail: value,
                  }))
                }
              />
            </AgxRow>
            <AgxRow>
              <AgxPhoneNumberInput
                id="phoneNumber"
                label="Phone Number"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={listingsPhone || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    listingsPhone: value,
                  }))
                }
              />
            </AgxRow>
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Address</AgxLabel>
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="streetAddress"
                label="Street Address"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={streetAddress || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      streetAddress: value,
                    },
                  }))
                }
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="suburb"
                label="Suburb"
                stretch
                noOptionalLabel
                defaultValue={suburb || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      suburb: value,
                    },
                  }))
                }
              />

              <AgxSelect
                id="state"
                label="State"
                hideOptionalLabel
                options={allStateOptions}
                defaultValue={addressStateDefaultValue}
                onValueChanged={({ value }: { value: string }) => {
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      state: value,
                    },
                  }))
                }}
              />

              <AgxTextInput
                id="postcode"
                label="Postcode"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={postalCode || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setLicenseeInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      postalCode: value,
                    },
                  }))
                }
              />
            </AgxRow>
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Extras</AgxLabel>
            </AgxRow>
            <AgxTextArea
              id="instructionsConditions"
              label="Reassignment Clause"
              parentControlValue
              defaultValue={instructionsConditions ?? ''}
              onInputValueChange={({ value }: { value: string }) =>
                setLicenseeInformation((previousState: AgencyDetails) => ({
                  ...previousState,
                  instructionsConditions: value,
                }))
              }
              rows={6}
              maxLength={1100}
              showCharCount
              noOptionalLabel
              stretch
            />
          </AgxColumn>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default LicenseeDetailsPage
