/* eslint-disable react/prop-types */
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxDivider,
  AgxHeader,
  AgxRow,
  AgxToast,
  Images,
} from '@urbanx/agx-ui-components'
import { Budget } from '../../types/executiveStatistics'
import BudgetDataRow from './BudgetDataRow'
import { GetBudgetStatistics, SetKeyMetricsBudget } from 'services'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useCallback, useEffect, useState } from 'react'
import './SetBudget.scss'
import ReactModal from 'react-modal'
import { AgxToastState } from 'types/commonTypes'

const SetBudget: React.FC = () => {
  const [, getAuthToken] = useAzureAuth()
  const queryClient = useQueryClient()

  const { data: keyMetricBudgetStatistics } = useQuery<Budget[] | undefined>({
    queryKey: ['budget-statistics'],
    queryFn: () => GetBudgetStatistics(getAuthToken),
  })

  const [isOpen, setIsOpen] = useState(false)
  const [budgetData, setBudgetData] = useState(keyMetricBudgetStatistics)

  useEffect(() => {
    setBudgetData(keyMetricBudgetStatistics)
  }, [keyMetricBudgetStatistics])

  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const { mutate: updateItems } = useMutation(SetKeyMetricsBudget, {
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['executive-statistics'],
        }),
      ])
      updateToastState({
        color: 'success',
        message: 'Budget set successfully',
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error while setting budget',
        open: true,
      })
    },
  })

  const handleSave = useCallback(() => {
    updateItems({
      budgetData: budgetData ?? [],
      getAuthToken: getAuthToken,
    })
    setIsOpen(false)
  }, [updateItems, budgetData, getAuthToken, setIsOpen])

  if (!budgetData) return null

  return (
    <>
      <AgxToast selector="#agxToast" toastState={toastState} />
      <AgxButton
        hollow
        medium
        wide
        onClick={() => setIsOpen(true)}
        text="Set Budget"
      />
      <ReactModal
        id={'budgetPopup'}
        style={{
          overlay: {
            backgroundColor: 'rgba(40,40,40, 0.75)',
          },
          content: {
            border: '1px solid #ccc',
            background: '#fff',
            outline: 'none',
            borderRadius: 10,
            height: 'fit-content',
            maxHeight: '80vh',
          },
        }}
        ariaHideApp={false}
        isOpen={isOpen}
      >
        <AgxColumn extraClasses="setBudgetModalContent">
          <AgxRow fill spaceBetween>
            <AgxHeader size={3}>Set Budget</AgxHeader>
            <div onClick={() => setIsOpen(false)}>
              <Images.CloseOutline width={25} height={25} />
            </div>
          </AgxRow>
          <AgxColumn extraClasses="budgetScrollableContent">
            <AgxDivider />
            <table className="budgetTable">
              <thead>
                <tr>
                  <th className="left">
                    <AgxBodyText small>Month</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>Sales Volume</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>Contracts</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>AV Sale Price</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>AV Comm Rate</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>AV Service Fee %</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>AV Service Fee $</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>Service Fees</AgxBodyText>
                  </th>
                  <th>
                    <AgxBodyText small>Breakeven</AgxBodyText>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 12 }).map((_, index) => (
                  <BudgetDataRow
                    budgetData={budgetData[index]}
                    onDataChange={(budget: Budget) => {
                      const updatedBudgetData = [...budgetData]
                      updatedBudgetData[index] = budget
                      const avgSalesPrice =
                        budget.contracts === 0
                          ? 0
                          : budget.salesVolume / budget.contracts || 0
                      updatedBudgetData[index] = {
                        ...budget,
                        avgSalesPrice,
                      }
                      setBudgetData(updatedBudgetData)
                    }}
                    key={index}
                  />
                ))}
              </tbody>
            </table>
          </AgxColumn>
        </AgxColumn>
        <AgxDivider />
        <AgxRow
          fill
          centered
          justifyCenter
          mediumGap
          extraClasses="setBudgetButtons"
        >
          <AgxButton onClick={handleSave} text="Save" primary large></AgxButton>
          <AgxButton
            onClick={() => setIsOpen(false)}
            text="Cancel"
            hollow
            large
          ></AgxButton>
        </AgxRow>
      </ReactModal>
    </>
  )
}

export default SetBudget
