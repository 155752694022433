import { useContext } from 'react'
import { UserContext } from '../../../contexts'
import { PageLayout } from '../../../layout'
import { AgxButton, Images } from '@urbanx/agx-ui-components'
import { useNavigate } from 'react-router-dom'
import '../error.css'

const PageNotFound = () => {
  const user = useContext(UserContext)
  const navigate = useNavigate()
  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName=""
      isError={true}
      transparent={true}
    >
      <div className="errorContainer__error">
        <div style={{ width: 500 }} className="errorContainer__error">
          <div className="circle__error">
            <Images.NotFound />
          </div>
          <p className="smallTextStyle_error">404 page not found</p>
          <p className="largeTextStyle__error">
            Hmm, the page you’re looking for doesn’t exist
          </p>
          <p className="smallTextStyle_error">
            The link you clicked might no longer exist, or you may have
            accidentally typed in the wrong URL.
          </p>
        </div>
        <AgxButton
          text="Return home"
          large
          primary
          onClick={() => navigate('/')}
        />
      </div>
    </PageLayout>
  )
}

export default PageNotFound
