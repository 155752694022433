import { AgxColumn, AgxRow, AgxLabel, UploadedDocument } from '@urbanx/agx-ui-components';
import DocumentSection from './DocumentSection';
import { DocumentGroup } from 'services/functions/campaigns/getDocumentsForCampaigns';

interface Props {
    documentsInfo: DocumentGroup[];
    downloadDocument: (document: UploadedDocument) => void;
    getDocumentTypeLabel: (documentType: string) => string;
}

const DocumentsList = (props: Props) => {

    const { documentsInfo, downloadDocument, getDocumentTypeLabel } = props
    return (
    <>
    {documentsInfo && documentsInfo.map(docGroup =>
        <AgxColumn key={docGroup.title} extraClasses='panelContainer files-panel'>
            <AgxRow extraClasses='panelTitleContainer'>
                <AgxLabel medium extraClasses='left-header'>{docGroup.title}</AgxLabel>
                <AgxLabel medium extraClasses='right-header'>Date Uploaded</AgxLabel>
            </AgxRow>
            {docGroup.documents &&
                <DocumentSection
                    documents={docGroup.documents}
                    downloadDocument={downloadDocument}
                    getDocumentTypeLabel={getDocumentTypeLabel}
                />
            }
        </AgxColumn>
    )}
    </>)
}

export default DocumentsList;
