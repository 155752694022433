import { QueryKey } from '@tanstack/react-query'
import { Staff } from 'types/staff'
import StaffService from 'services/staffService'

const GetUrbanXStaffByRoles = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<Staff[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return;

  const service = new StaffService(token)

  if (!service) return;

  const roles = queryKey[1] as string[]

  const result = await service.staffService.get(
    'getStaffByRoles', { roles: roles }
  )

  return result.data;
}

export default GetUrbanXStaffByRoles