import { useState, useEffect, useContext, Suspense } from 'react'
import { MenuTitles } from 'constants/menu'
import { AgencyContext, UserContext } from 'contexts'
import { ContentLayout, PageLayout } from 'layout'
import {
  AgxRow,
  AgxColumn,
  AgxHeader,
  AgxLabel,
  AgxTextInput,
  AgxBodyText,
  AgxToast,
  AgxDivider,
  AgxFilterableTableHeader,
  Images,
  AgxCheckbox,
} from '@urbanx/agx-ui-components'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { AgxToastState } from 'types/commonTypes'
import placeHolderImage from 'assets/images/placeholder-property.png'
import './Listings.scss'
import { ActiveListing, ListingsTableColumn } from 'types/rex'
import { GetActiveListingsForAgency, SetListingPublishedToPortal } from 'services'

const ListingsPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const [searchText, setSearchText] = useState('')
  const [shouldResetFilters, setShouldResetFilters] = useState(false)

  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const user = useContext(UserContext)
  const [selectedAgency] = useContext(AgencyContext)
  const [, getAuthToken] = useAzureAuth()

  interface Column {
    id: string
    name: string
    options: string[] | object[]
  }

  const { data: loadedListings, isLoading: isLoadingListings } = useQuery<
    ActiveListing[] | undefined
  >({
    queryKey: ['listings', agencyId],
    queryFn: (queryKey) => GetActiveListingsForAgency(queryKey, getAuthToken),
  })

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['listings', agencyId],
    })
  }, [])

  const [columns, setColumns] = useState<Column[]>([])
  const [listings, setListings] = useState<ActiveListing[]>([])
  const [filteredListings, setFilteredListings] = useState<ActiveListing[]>([])
  const [settingPublishedToPortal, setSettingPublishedToPortal] =
    useState(false)

  const { mutate: setListingPublishedToPortal } = useMutation(
    SetListingPublishedToPortal,
    {
      onSuccess: () => {
        updateToastState({
          color: 'success',
          message: 'Listing publish status updated',
          open: true,
        })
        setSettingPublishedToPortal(false)
      },
      onError: () => {
        updateToastState({
          color: 'error',
          message: 'Error setting listing publish status',
          open: true,
        })
      },
    }
  )

  useEffect(() => {
    const listings = loadedListings?.filter((listing) => {
      if (!searchText) return true
      if (!listing.address?.toLowerCase().includes(searchText.toLowerCase())) {
        return false
      }
      return true
    })

    setListings(listings ?? [])
  }, [loadedListings, searchText])

  useEffect(() => {
    const cols: Column[] = [
      {
        id: ListingsTableColumn.Image,
        name: 'Property',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: ListingsTableColumn.Address,
        name: 'Address',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: ListingsTableColumn.Agents,
        name: 'Agents',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: ListingsTableColumn.Published,
        name: 'Published',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
    ]

    if (!listings) {
      setColumns(cols)
      setShouldResetFilters(true)
      setFilteredListings(listings)
      return
    }

    const agents = listings.flatMap((listing) => listing.agents)

    cols[6].options = [...new Set(agents)]
    cols[9].options = ['Yes', 'No']

    setColumns(cols)
    setShouldResetFilters(true)
    setFilteredListings(listings)
  }, [listings])

  const applyFilters = (filters: { [key: string]: string[] }) => {
    if (!listings) {
      setFilteredListings([])
      return
    }

    // Apply filters to the data
    const filteredRows = listings.filter((row) => {
      return Object.entries(filters).every(([column, selectedValues]) => {
        if (selectedValues.length === 0) return true

        if (column === ListingsTableColumn.Agents) {
          const agents = row.agents
          return agents.some((agent) => selectedValues.includes(agent))
        }

        if (column === ListingsTableColumn.Published) {
          const published = row.pushedToPortal

          if (selectedValues.includes('Yes') && published) return true
          if (selectedValues.includes('No') && !published) return true

          return false
        }
      })
    })

    setFilteredListings(filteredRows)
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Listings"
    >
      <ContentLayout hasSideMenu={true} activeMenu={MenuTitles.LISTINGS}>
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn largeGap>
          <>
            <AgxRow end>
              <AgxTextInput
                id="listingsSearch"
                label=""
                defaultValue={searchText}
                onInputValueChange={({ value }: { value: string }) =>
                  setSearchText(value)
                }
                noHeader
                noOptionalLabel
                placeholder="Search"
                leftIcon={<Images.SearchOutline />}
              />
            </AgxRow>
            <AgxDivider />
            <AgxRow>
              <table className="listingsTable">
                <tbody>
                  <AgxFilterableTableHeader
                    columns={columns}
                    shouldResetFilters={shouldResetFilters}
                    setShouldResetFilters={setShouldResetFilters}
                    onFilterChange={applyFilters}
                  />
                  {isLoadingListings ? (
                    <Suspense>
                      <tr>
                        <td colSpan={11}>
                          <AgxLabel large>Loading Listings...</AgxLabel>
                        </td>
                      </tr>
                    </Suspense>
                  ) : (
                    <>
                      {filteredListings?.map((listing, index) => {
                        return (
                          <tr key={index} className="listingsRow">
                            <td colSpan={3} align="left">
                              <img
                                className="propertyImage"
                                width={60}
                                src={listing.imageUri ?? placeHolderImage}
                              />
                            </td>
                            <td colSpan={3} align="left">
                              <AgxHeader size={4}>{listing.address}</AgxHeader>
                            </td>
                            <td colSpan={3} align="left">
                              <AgxBodyText small>
                                {listing.agents?.join(', ')}
                              </AgxBodyText>
                            </td>
                            <td colSpan={3} align="left">
                              <AgxCheckbox
                                id={`publishedCheckbox-${listing.listingId}`}
                                defaultValue={listing.pushedToPortal}
                                disabled={settingPublishedToPortal}
                                onValueChanged={({ value }) => {
                                  if (listing.pushedToPortal === value) return

                                  setSettingPublishedToPortal(true)
                                  setListingPublishedToPortal({
                                    getAuthToken,
                                    ListingId: listing.listingId,
                                    PublishToPortal: value,
                                  })
                                  setFilteredListings((prev) =>
                                    prev.map((prevListing) => {
                                      if (
                                        prevListing.listingId ===
                                        listing.listingId
                                      ) {
                                        return {
                                          ...prevListing,
                                          pushedToPortal: value,
                                        }
                                      }
                                      return prevListing
                                    })
                                  )
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })}
                      {filteredListings.length === 0 && !isLoadingListings && (
                        <tr>
                          <td colSpan={11}>
                            <AgxLabel large>No Listings found</AgxLabel>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </AgxRow>
          </>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default ListingsPage
