import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import { UploadedDocument } from '../../../types/agency'
import AgencyService from '../../agenciesService'

interface UpdateBrandingRequest extends ApiRequestBody {
  selectedFile: File
  AgencyId?: string
  getAuthToken: () => Promise<string | void>
}

const UploadPublicDocument = async (
  requestBody: UpdateBrandingRequest
): Promise<UploadedDocument | undefined> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const { AgencyId } = requestBody

  if (!service || !AgencyId) return

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)

  const formdata = new FormData()
  formdata.append('File', requestBody.selectedFile)

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formdata,
    redirect: 'follow',
  }

  return await fetch(
    `${service.agencyService.baseUrl}UploadPublicDocument?AgencyId=${AgencyId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result) as UploadedDocument)
}

export default UploadPublicDocument
