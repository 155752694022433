import './placeholderimage.css'

interface Props {
  title: string
  size: number
}

const PlaceHolderImage = (props: Props) => {
  const { title, size } = props
  const firstCharacter = title.substring(0, 1).toUpperCase()
  const fontSize = size < 40 ? size - 10 : size - 25

  return (
    <div className="imageContainer">
      <span
        className="imageStyle"
        style={{
          height: `${size}px`,
          width: `${size}px`,
          lineHeight: `${size}px`,
          fontSize: `${fontSize}px`,
        }}
      >
        {firstCharacter}
      </span>
    </div>
  )
}

export default PlaceHolderImage
