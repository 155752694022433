import { QueryKey } from '@tanstack/react-query'
import AgencyService from '../../agenciesService'
import { Team } from '../../../types/agency'

const getTeamsForAgency = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<Team[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return []

  const service = new AgencyService(token)

  if (!service) return

  const agencyId = queryKey[1] as string

  const result = await service.agencyService.get('getTeamsForAgency', {
    agencyId: agencyId,
  })

  return result.data || []
}

export default getTeamsForAgency