import {
    AgxBodyText, AgxButton, AgxColumn,
    AgxDivider, AgxHeader, AgxRow, Images
} from "@urbanx/agx-ui-components"
import { AgencyUrbanXTeam, Staff } from "../../../../types/staff"
import './UrbanXTeam.scss'

interface UrbanXTeamProps {
    agencyId: string
    urbanXTeam: AgencyUrbanXTeam
    onClick: (showStaff: boolean) => void
  }
  
const UrbanXTeam = (props: UrbanXTeamProps) => {    
    const {
        urbanXTeam: {
            businessManager,
            listingsAdmin,
            salesAdmin,
            marketingExpert    
        },
        onClick
    } = props;

    const processStaffName = (staff: Staff | undefined) : JSX.Element => {
        let staffName = 'None';
        if (staff) {
            staffName = `${staff.firstName} ${staff.lastName}`;
        }

        return (
            <AgxBodyText neutralGrayColor={!staff}>
                {staffName}
            </AgxBodyText>
        );
    }
    
    return (
        <AgxColumn largeGap extraClasses={"teamSection"}>
            <AgxRow spaceBetween centered>
                <AgxHeader size={5}>{"UrbanX Team"}</AgxHeader>
                <AgxButton
                    text="Manage Staff"
                    medium
                    primary
                    rightIcon={<Images.People />}
                    onClick={() => onClick(true)}
                />
            </AgxRow>
            <AgxDivider />
            <AgxRow spaceBetween>
                <AgxColumn>
                    Business Manager
                    {processStaffName(businessManager)}
                </AgxColumn>
                <AgxColumn>
                    Listings Administrator
                    {processStaffName(listingsAdmin)}
                </AgxColumn>
                <AgxColumn>
                    Sales Administrator
                    {processStaffName(salesAdmin)}
                </AgxColumn>
                <AgxColumn>
                    Marketing Expert
                    {processStaffName(marketingExpert)}
                </AgxColumn>
            </AgxRow>
        </AgxColumn>
    )
}

export default UrbanXTeam