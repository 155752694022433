import { QueryKey } from '@tanstack/react-query'
import CampaignsService from '../../campaignsService'
import { Campaign } from '../../../types/campaigns'

const getCampaignsForAgency = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<Campaign[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const agencyId = queryKey[1] as string

  const result = await service.campaignsService.get('GetCampaignsForAgency', {
    AgencyId: agencyId,
  })

  return result.data
}

export default getCampaignsForAgency
