import { ReactElement, useRef } from 'react'
import { PageHeader, PageHeaderProps } from '../../components'
import './pagelayout.css'

export interface PageLayoutProps extends PageHeaderProps {
  children: ReactElement | ReactElement[]
  transparent?: boolean
}

type PageHeaderHandle = React.ElementRef<typeof PageHeader>

const PageLayout = (props: PageLayoutProps) => {
  const { children, transparent, isStaff } = props
  const ref = useRef<PageHeaderHandle>(null)

  return (
    <div onClick={() => ref && ref.current && ref.current.closeMenuIfOpen()}>
      <PageHeader
        {...props}
        agencyName={isStaff ? 'UrbanX' : props.agencyName}
        ref={ref}
      />
      <div className={`pageBody ${transparent ? 'transparent' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default PageLayout
