import {
  AgxBodyText,
  AgxColumn,
  AgxHeader,
  AgxRow,
  AgxTextInput,
  EntityImage,
  Images,
} from '@urbanx/agx-ui-components'
import './Partners.scss'
import { useContext, useMemo, useState } from 'react'
import { PageLayout } from 'layout'
import { AgencyContext, UserContext } from 'contexts'
import { useQuery } from '@tanstack/react-query'
import { Agency } from 'types/agency'
import { GetAllAgencies } from 'services'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useNavigate } from 'react-router-dom'
import { StaffGetAllAgents, AgentDetailsProjection } from 'services'

enum SelectedTab {
  Partner,
  Individual,
}

const Partners = () => {
  const user = useContext(UserContext)
  const [, getAuthToken] = useAzureAuth()
  const [, setSelectedAgency] = useContext(AgencyContext)

  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.Partner
  )

  const [searchText, setSearchText] = useState('')

  const { data: agencies, isLoading: isLoadingAgencies } = useQuery<
    Agency[] | undefined
  >({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const { data: agents, isLoading: isLoadingAgents } = useQuery<
    AgentDetailsProjection[] | undefined
  >({
    queryKey: ['all-agents'],
    queryFn: () => StaffGetAllAgents(getAuthToken),
  })

  const filteredAgencies = useMemo(() => {
    const lowerCaseSearch = searchText.toLowerCase()

    return (
      agencies?.filter(
        (agency) =>
          lowerCaseSearch === '' ||
          agency.name.toLowerCase().includes(lowerCaseSearch)
      ) ?? []
    )
  }, [searchText, agencies])

  const filteredAgents = useMemo(() => {
    const lowerCaseSearch = searchText.toLowerCase()

    return (
      agents?.filter((agent) => {
        const fullName =
          `${agent.name?.firstName} ${agent.name?.lastName}`.toLowerCase()
        return lowerCaseSearch === '' || fullName.includes(lowerCaseSearch)
      }) ?? []
    )
  }, [searchText, agents])

  const navigate = useNavigate()

  return (
    <>
      <PageLayout
        agentName={user?.firstName || ''}
        currentPageTitle="Partner"
        agencyName=""
        isStaff
      >
        <AgxColumn fill extraClasses="partnersContent">
          <AgxRow extraClasses="partnersContentHeader" fill centered>
            <AgxRow extraClasses="partnersContentHeaderTabs">
              <AgxHeader
                size={5}
                strong={selectedTab === SelectedTab.Partner}
                onClick={() => setSelectedTab(SelectedTab.Partner)}
              >
                Partner
              </AgxHeader>
              <AgxHeader
                size={5}
                strong={selectedTab === SelectedTab.Individual}
                onClick={() => setSelectedTab(SelectedTab.Individual)}
              >
                Individuals
              </AgxHeader>
            </AgxRow>
            <AgxTextInput
              id="partnerSearch"
              leftIcon={<Images.SearchOutline />}
              noHeader
              noOptionalLabel
              placeholder="Search"
              stretch
              defaultValue={searchText}
              onInputValueChange={(value) => setSearchText(value.value)}
            />
          </AgxRow>
          <AgxColumn fill extraClasses="partnersList">
            <AgxRow extraClasses="partnerItem">
              <AgxBodyText small extraClasses="partnersTitle">
                Partner
              </AgxBodyText>
            </AgxRow>
            {selectedTab === SelectedTab.Partner &&
              !isLoadingAgencies &&
              filteredAgencies?.map((agency) => {
                return (
                  <AgxRow
                    extraClasses="partnerItem"
                    key={agency.id}
                    centered
                  onClick={() => {
                      setSelectedAgency(
                        agencies?.find((a) => a.id === agency.id)
                      )
                      navigate(`/partner/agency/${agency.id}`)
                    }}
                  >
                    <EntityImage
                      addRingToImage={false}
                      name={agency.name}
                      photoUri={agency.logoUrl}
                      backgroundColour={agency.backgroundColor}
                    />
                    <AgxBodyText small>{agency.name}</AgxBodyText>
                  </AgxRow>
                )
              })}
            {selectedTab === SelectedTab.Individual &&
              !isLoadingAgents &&
              filteredAgents?.map((agent) => {
                const name = `${agent.name.firstName} ${agent.name.lastName}`

                return (
                  <AgxRow
                    extraClasses="partnerItem"
                    key={agent.id}
                    centered
                    onClick={() => {
                      setSelectedAgency(
                        agencies?.find((a) => a.id === agent.agencyId)
                      )
                      navigate(`/partner/individual/${agent.id}`)
                    }}
                  >
                    <EntityImage
                      addRingToImage={true}
                      name={name}
                      photoUri={agent.photoUri}
                    />
                    <AgxBodyText small>{name}</AgxBodyText>
                  </AgxRow>
                )
              })}
          </AgxColumn>
        </AgxColumn>
      </PageLayout>
    </>
  )
}

export default Partners
