import {
  AgxBodyText,
  AgxButton,
  AgxColours,
  AgxColumn,
  AgxMultiSelect,
  AgxRow,
  AgxTextInput,
  EntityImage,
  Images,
} from '@urbanx/agx-ui-components'
import { Team, TeamMember, UploadedDocument } from 'types/agency'
import { useEffect, useState } from 'react'
import { PlaceHolderImage } from 'components'
import FileUpload from 'components/file-upload/FileUpload'
import { useMutation } from '@tanstack/react-query'
import { UploadPublicDocument } from 'services'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useParams } from 'react-router-dom'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'

interface AddTeamProps extends Team {
  index: number
  agents: TeamMember[]
  updateTeamName: (id: string, value: string) => void
  updateTeamMembers: (id: string, value: string) => void
  updateTeamPhoto: (id: string, photoUri: string) => void
  onDeleteTeamHandler: (id: string) => void
}

const AddTeam = (props: AddTeamProps) => {
  const {
    id,
    name,
    agents,
    members,
    photoUri,
    index,
    updateTeamName,
    updateTeamMembers,
    updateTeamPhoto,
    onDeleteTeamHandler,
  } = props

  const [orderedMembersList, setOrderedMembersList] = useState<string[]>([])
  const [img, updateImg] = useState(photoUri ?? '')
  const [_logo, setLogo] = useState<UploadedDocument | undefined>(undefined)
  const [, getAuthToken] = useAzureAuth()
  const { agencyId } = useParams()
  //const [buttonOpacity, setButtonOpacity] = useState(0.5)

  const { mutate: uploadPublicDocument } = useMutation(UploadPublicDocument, {
    onSuccess: (r) => {
      updateImg(r?.publicPath || '')
      updateTeamPhoto(id, r?.publicPath || '')
      setLogo(r)
    },
  })

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (orderedMembersList && orderedMembersList?.length > 0) {
      const items = [...orderedMembersList]
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)

      setOrderedMembersList(items)
    }
  }

  useEffect(() => {
    if (members) {
      setOrderedMembersList(members)
    }
  }, [members])

  const useAgentImage = (name: string, photo?: UploadedDocument) => {
    updateTeamName(id, name)
    if (photo) {
      updateImg(photo.publicPath)
      updateTeamPhoto(id, photo.publicPath)
    } else {
      updateImg('')
      updateTeamPhoto(id, '')
    }
  }

  const toggleOpacity = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    opacity: number
  ) => {
    event.currentTarget.style.setProperty('opacity', `${opacity}`)
  }

  const renderItem = (memberId: string, index: number) => {
    const member = agents.find((agent) => agent.id === memberId)
    if (member) {
      return (
        <Draggable key={memberId} draggableId={memberId} index={index}>
          {(provided) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <td width={'100%'}>
                <div className="thPackageNameContainer__marketing">
                  <Images.DragDropIcon />
                  {member.photo ? (
                    <img
                      src={member.photo.publicPath}
                      style={{
                        height: 45,
                        width: 45,
                        borderRadius: '50%',
                        marginRight: 16,
                      }}
                    />
                  ) : (
                    <PlaceHolderImage size={45} title={member.firstName} />
                  )}
                  <AgxBodyText small>
                    {member.firstName} {member.lastName}
                  </AgxBodyText>
                </div>
              </td>
              <td align="left">
                <AgxRow spaceBetween>
                  <div
                    id={`${member.id}`}
                    style={{
                      display: 'flex',
                      opacity: 0.5,
                      width: '150px',
                      justifyContent: 'flex-end',
                    }}
                    onMouseOver={(event) => toggleOpacity(event, 1)}
                    onMouseOut={(event) => toggleOpacity(event, 0.5)}
                  >
                    <AgxButton
                      text="Use"
                      medium
                      naked
                      leftIcon={<Images.Picture />}
                      onClick={() =>
                        useAgentImage(
                          `${member.firstName} ${member.lastName}`,
                          member.photo
                        )
                      }
                    />
                  </div>
                </AgxRow>
              </td>
            </tr>
          )}
        </Draggable>
      )
    }
  }

  const updateOrderedMemberList = (value: string) => {
    const memberIds = value.split(',')
    // team member added
    if (memberIds.length > orderedMembersList.length) {
      const newMember = memberIds.filter(
        (member) => !orderedMembersList.includes(member)
      )
      setOrderedMembersList([...newMember, ...orderedMembersList])
    }

    // team member removed
    if (memberIds.length < orderedMembersList.length) {
      const memberRemoved = orderedMembersList.filter(
        (member) => !memberIds.includes(member)
      )
      setOrderedMembersList(
        orderedMembersList.filter((member) => member !== memberRemoved[0])
      )
    }
  }

  const uploadLogo = (file: File) => {
    updateImg(URL.createObjectURL(file))
    uploadPublicDocument({
      selectedFile: file,
      AgencyId: agencyId,
      getAuthToken,
    })
  }

  return (
    <AgxColumn extraLargeGap extraClasses={'addTeamsContainer__manageTeams'}>
      <AgxRow veryLargeGap>
        <AgxTextInput
          id="txtTeamName"
          label=""
          noOptionalLabel
          placeholder={`Team ${index}`}
          leftIcon={<Images.PeopleBlack />}
          stretch
          defaultValue={name}
          parentControlValue
          onInputValueChange={({ value }: { value: string }) =>
            updateTeamName(id, value)
          }
        />
        <AgxButton
          text=""
          small
          naked
          leftIcon={<Images.CrossFill />}
          onClick={() => onDeleteTeamHandler(id)}
        />
      </AgxRow>
      <AgxRow largeGap extraClasses="flexCentre">
        <EntityImage
          name={name}
          addRingToImage
          backgroundColour={`var(--${AgxColours.NEUTRAL_GREY_500})`}
          photoUri={img}
          size={72}
          usePlaceholderImage
        />
        <FileUpload
          id="fileUpload"
          title=""
          buttonText={img ? 'Change' : 'Upload'}
          wide
          extensions={['jpg', 'jpeg', 'png', 'svg', 'bmp']}
          uploadFile={(file) => uploadLogo(file)}
          dark
          hideIcon
        />
        {img && (
          <AgxButton
            text="Remove"
            medium
            hollow
            primary
            onClick={() => {
              updateImg('')
              updateTeamPhoto(id, '')
              setLogo(undefined)
            }}
          />
        )}
      </AgxRow>
      <div key={`team-${id}-${index}`} className="teamSection__manageTeams">
        <AgxMultiSelect
          id={`team-${id}-${index}`}
          label="Team Members"
          hideOptionalLabel
          defaultValue={orderedMembersList}
          onValueChanged={({ value }: { value: string[] }) => {
            updateOrderedMemberList(value.join(','))
            updateTeamMembers(id, value.join(','))
          }}
          options={agents.map((a) => ({
            value: a.id,
            label: `${a.firstName} ${a.lastName}`,
          }))}
        />
        <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
          <Droppable droppableId="teamMembersList">
            {(provided) => (
              <table
                {...provided.droppableProps}
                ref={provided.innerRef}
                width="100%"
              >
                <tbody>
                  {orderedMembersList &&
                    orderedMembersList.map((memberId, index) =>
                      renderItem(memberId, index)
                    )}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </AgxColumn>
  )
}

export default AddTeam
