import { useState, useRef, ReactNode } from 'react'
import { Campaign, CampaignStageAction } from 'types/campaigns'
import useOutsideAlert from 'hooks/useOutsideAlert'
import { CampaignStage, Images } from '@urbanx/agx-ui-components'
import { getEnumValue } from 'helpers/enumHelper'

interface CampaignOptionProps {
  onPerformCampaignAction(campaign: Campaign, action: CampaignStageAction): void
  isArchived: boolean
  selectedCampaign: Campaign
}

const CampaignOptions = (props: CampaignOptionProps) => {
  const {
    onPerformCampaignAction,
    selectedCampaign,
    isArchived,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const moreOptionsRef = useRef(null)
  useOutsideAlert(moreOptionsRef, (onTriggerOutside: string) => {
    if (onTriggerOutside === 'outside') setIsOpen(false)
  })

  const handleToggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const ActionLink = ({ action, children }: { action: CampaignStageAction, children: ReactNode }) => (
    <div>
      <a onClick={() => onPerformCampaignAction(selectedCampaign, action)}>{children}</a>
    </div>
  )

  const actionsByStage = (campaignStage: CampaignStage) => {
    switch (getEnumValue(CampaignStage, campaignStage)) {
      case CampaignStage.AgreementDraft:
      case CampaignStage.AgreementAgentSigning:
      case CampaignStage.AgreementVendorSigning:
      case CampaignStage.AgreementSignedByAllParties:
        return [<ActionLink key='archive' action={CampaignStageAction.Archive}>Archive</ActionLink>]
      case CampaignStage.ListingCurrent:
        return [
          <ActionLink key='markAsSold' action={CampaignStageAction.MarkAsSold}>Mark as Sold</ActionLink>,
          <ActionLink key='markAsUnderContract' action={CampaignStageAction.MarkAsUnderContract}>Mark as Under Contract</ActionLink>,
          <ActionLink key='withdraw' action={CampaignStageAction.Withdraw}>Withdraw</ActionLink>
        ]
      case CampaignStage.ListingUnderContract:
        return [
          <ActionLink key='markAsSold' action={CampaignStageAction.MarkAsSold}>Mark as Sold</ActionLink>,
          <ActionLink key='revertToCurrent' action={CampaignStageAction.RevertToCurrent}>Revert to Current</ActionLink>
        ]
      default:
        return []
    }
  }

  const actions = actionsByStage(selectedCampaign.stage);

  return (
    <div onClick={handleToggleOpen} className="hover-container" key={selectedCampaign.id} ref={moreOptionsRef}>
      <Images.More />
      {isOpen && (
        <div className="hover-element" id={selectedCampaign.id}>
          {!isArchived && actions.length > 0 ?
             actions :
            <ActionLink key='default' action={isArchived ? CampaignStageAction.Restore : CampaignStageAction.Archive}>
              {isArchived ? CampaignStageAction.Restore : CampaignStageAction.Archive}
            </ActionLink>
          }
        </div>
      )}
    </div>
  )
}

export default CampaignOptions