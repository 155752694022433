import {
  AgxCurrency,
  AgxInputText,
  AgxLabel,
  AgxPercent,
  AgxTextInput,
  formatCurrency,
} from '@urbanx/agx-ui-components'
import { Budget } from '../../types/executiveStatistics'

interface BudgetRowProps {
  budgetData: Budget
  onDataChange: (budget: Budget) => void
}

const monthsOfYear = [
  'Jan',
  'Feb',
  'Mar',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const BudgetDataRow = ({ budgetData, onDataChange }: BudgetRowProps) => {
  return (
    <tr>
      <td className="left">
        <AgxLabel medium>
          {monthsOfYear[budgetData?.budgetMonth - 1]} {budgetData?.budgetYear}
        </AgxLabel>
      </td>
      <td>
        <AgxCurrency
          stretch
          decimalPoints={0}
          id={`salesVolume`}
          optionalLabelText=""
          onInputValueChange={({ value }) => {
            const Value = Number(value)
            onDataChange({ ...budgetData, salesVolume: Value })
          }}
          defaultValue={String(budgetData?.salesVolume)}
        />
      </td>
      <td>
        <AgxTextInput
          stretch
          numberOnly
          noDecimalPoints
          id={`contracts`}
          optionalLabelText=""
          onInputValueChange={({ value }) => {
            const Value = Number(value)
            onDataChange({ ...budgetData, contracts: Value })
          }}
          defaultValue={String(budgetData?.contracts)}
        />
      </td>
      <td>
        <AgxInputText medium>
          {formatCurrency(budgetData?.avgSalesPrice)}
        </AgxInputText>
      </td>
      <td>
        <AgxPercent
          stretch
          decimalPoints={2}
          id={`avgCommRate`}
          optionalLabelText=""
          onInputValueChange={({ value }) => {
            const Value = Number(value)
            onDataChange({ ...budgetData, avgCommRate: Value })
          }}
          defaultValue={String(budgetData?.avgCommRate)}
        />
      </td>
      <td>
        <AgxPercent
          stretch
          decimalPoints={2}
          id={`avgServiceFeePercent`}
          optionalLabelText=""
          onInputValueChange={({ value }) => {
            const Value = Number(value)
            onDataChange({ ...budgetData, avgServiceFeePercent: Value })
          }}
          defaultValue={String(budgetData?.avgServiceFeePercent)}
        />
      </td>
      <td>
        <AgxCurrency
          stretch
          decimalPoints={0}
          id={`avgServiceFeeDollars`}
          optionalLabelText=""
          onInputValueChange={({ value }) => {
            const Value = Number(value)
            onDataChange({ ...budgetData, avgServiceFeeDollars: Value })
          }}
          defaultValue={String(budgetData?.avgServiceFeeDollars)}
        />
      </td>
      <td>
        <AgxCurrency
          stretch
          decimalPoints={0}
          id={`serviceFee`}
          optionalLabelText=""
          onInputValueChange={({ value }) => {
            const Value = Number(value)
            onDataChange({ ...budgetData, serviceFees: Value })
          }}
          defaultValue={String(budgetData?.serviceFees)}
        />
      </td>
      <td>
        <AgxCurrency
          stretch
          decimalPoints={0}
          id={`breakeven`}
          optionalLabelText=""
          onInputValueChange={({ value }) => {
            const Value = Number(value)
            onDataChange({ ...budgetData, breakeven: Value })
          }}
          defaultValue={String(budgetData?.breakeven)}
        />
      </td>
    </tr>
  )
}

export default BudgetDataRow
