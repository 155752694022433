import { useEffect, useState } from 'react'
import { Color, ColorResult, SketchPicker } from 'react-color'
import { AgxLabel } from '@urbanx/agx-ui-components'
import './agxColorPicker.css'
interface AGXColorPickerProps {
  id: string
  onColorChange: (color: Color) => void
  defaultColor?: string
  label?: string
}
const AGXColorPicker = (props: AGXColorPickerProps) => {
  const { defaultColor, onColorChange, label, id } = props
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [selectedColor, setSelectedColor] = useState<Color>('#000000')

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color: ColorResult) => {
    setSelectedColor(color.hex.toUpperCase())
    onColorChange(color.hex.toUpperCase())
  }

  useEffect(() => {
    if (defaultColor) setSelectedColor(defaultColor.toUpperCase())
  }, [defaultColor])

  return (
    <div>
      <div className="header">
        <div className="labelLeft">
          {label && (
            <AgxLabel medium data-testid={`agx-${id}Required`} htmlFor={id}>
              {label}
            </AgxLabel>
          )}
        </div>
      </div>
      <div className="colorPickerContainer">
        <div className="swatch" onClick={handleClick}>
          <div
            className="color"
            style={{
              background: selectedColor.toString(),
            }}
          />
        </div>
        {displayColorPicker ? (
          <div className="popover">
            <div className="cover" onClick={handleClose} />
            <SketchPicker color={selectedColor} onChange={handleChange} />
          </div>
        ) : null}

        <span>{selectedColor.toString()}</span>
      </div>
    </div>
  )
}

export default AGXColorPicker
