import { StaffApi } from '../helpers/http'

class DomainService {
  authToken: string
  domainService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.domainService = new StaffApi('domain', authToken)
  }
}

export default DomainService
