import AgencyService from 'services/agenciesService'
import { Team } from 'types/agency'
import { ApiRequestBody } from 'interfaces/ApiRequestBody'

interface SetTeamsForAgencyRequest extends ApiRequestBody {
  agencyId: string
  agencyTeams: Team[]
}
const SetTeamsForAgency = async (
  requestBody: SetTeamsForAgencyRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const { agencyId, agencyTeams } = requestBody

  await service.agencyService.put('SetTeamsForAgency', {
    agencyId: agencyId,
    agencyTeams: agencyTeams,
  })
}

export default SetTeamsForAgency