import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import { AgencyDetails } from '../../../types/agency'
import AgencyService from '../../agenciesService'

interface AgencyDetailsRequest extends ApiRequestBody, AgencyDetails {}

const UpdateAgency = async (
  requestBody: AgencyDetailsRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put('UpdateAgency', {
    ...requestProperties,
  })

  return result.status
}

export default UpdateAgency
