import { PropertyAddress } from '@urbanx/agx-ui-components'

export const cleanTwoLineAddressWithoutState = (
  property: PropertyAddress
): string[] | null[] => {
  if (!property) return [null]

  const { unitNumber, streetAddress, suburb, postalCode } = property

  return [
    concatenateAddress([unitNumber, streetAddress], '/'),
    concatenateAddress([suburb, postalCode]),
  ]
}

export const cleanTwoLineAddress = (
  address: PropertyAddress | undefined
): string[] | null[] => {
  if (!address) return [null, null]

  const { unitNumber, streetAddress, suburb, state, postalCode } = address

  return [
    concatenateAddress([unitNumber, streetAddress], '/'),
    concatenateAddress([suburb, state, postalCode]),
  ]
}

const concatenateAddress = (elements: string[], joiner = ' '): string => {
  return elements.filter((e) => e != null && e !== '').join(joiner)
}
