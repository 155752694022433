import { QueryKey } from '@tanstack/react-query'
import AgencyService from '../../agenciesService'
import { AgencyDetails } from '../../../types/agency'

const getAgencyDetails = async (
  {
    queryKey,
  }: {
    queryKey: QueryKey
  },
  getAuthToken: () => Promise<string | void>
): Promise<AgencyDetails | undefined> => {
  const agencyId = queryKey[1] as string

  if (!agencyId) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get('GetAgencyDetails', {
    agencyId,
  })

  return result.data
}

export default getAgencyDetails
