import { Budget } from 'types/executiveStatistics';
import AgencyService from 'services/agenciesService';

const getBudgetStatistics = async (
  getAuthToken: () => Promise<string | void>,
): Promise<Budget[] | undefined> => {
    const token = await getAuthToken()
    if (!token) return undefined;

    const service = new AgencyService(token);
    
    if (!service) return undefined;

    const result = await service.agencyService.get('GetExecutiveKeyBudget');
    return result.data || [];
}

export default getBudgetStatistics;