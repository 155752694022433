import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface UpdateMarketingPackageOrder extends ApiRequestBody {
  AgencyId: string
  marketingPackageIds: string[]
}

const UpdateMarketingPackageOrder = async (
  requestBody: UpdateMarketingPackageOrder
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  await service.agencyService.put('UpdateMarketingPackageOrder', {
    AgencyId: requestBody.AgencyId,
    marketingPackageIds: requestBody.marketingPackageIds,
  })
}

export default UpdateMarketingPackageOrder