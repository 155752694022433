import { PackageItem } from '../../../types/agency'
import AgencyService from '../../agenciesService'

const SetMarketingItems = async ({
  agencyId,
  marketingItems,
  getAuthToken,
}: {
  agencyId: string
  marketingItems: PackageItem[]
  getAuthToken: () => Promise<string | void>
}): Promise<number | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put('SetMarketingItems', {
    AgencyId: agencyId,
    MarketingItems: marketingItems,
  })

  return result.status
}

export default SetMarketingItems
