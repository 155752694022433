import { RouterProvider } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import useAuth from './hooks/useAuth'
import './App.css'
import router from './constants/router'
import { useState } from 'react'
import { AgencyContext } from './contexts'
import { Agency } from './types/agency'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import '@urbanx/agx-ui-components/dist/esm/index.css'

const App = () => {
  LogRocket.init('dzljjh/autopilot')
  setupLogRocketReact(LogRocket)
  const { msalInstance } = useAuth()
  const agency = useState<Agency | undefined>(undefined)

  return (
    <MsalProvider instance={msalInstance}>
      <AgencyContext.Provider value={agency}>
        <RouterProvider router={router} />
      </AgencyContext.Provider>
    </MsalProvider>
  )
}

export default App
