import { QueryKey } from '@tanstack/react-query'
import AgencyService from 'services/agenciesService'
import { AgencyUrbanXTeam } from 'types/staff'

const GetUrbanXTeamForAgency = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<AgencyUrbanXTeam | undefined> => {
  const token = await getAuthToken()
  if (!token) return;

  const service = new AgencyService(token)

  if (!service) return;

  const agencyId = queryKey[1] as string

  const result = await service.agencyService.get(
    'GetUrbanXTeamForAgency',
    { agencyId: agencyId }
  )

  return result.data;
}

export default GetUrbanXTeamForAgency