import { QueryKey } from '@tanstack/react-query'
import RexService from '../../rexService'
import { ActiveListing } from '../../../types/rex'

const GetActiveListingsForAgency = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<ActiveListing[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agencyId = queryKey[1] as string
  const service = new RexService(token)

  if (!service) return undefined

  const result = await service.rexService.get('GetActiveListingsForAgency', { agencyId })

  return result.data
}

export default GetActiveListingsForAgency
