import { QueryKey } from '@tanstack/react-query'
import AgencyService from '../../agenciesService'
import { PackageItem } from '../../../types/agency'

const getMarketingItemsForStaff = async (
  {
    queryKey,
  }: {
    queryKey: QueryKey
  },
  getAuthToken: () => Promise<string | void>
): Promise<PackageItem[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const agencyId = queryKey[1] as string

  const result = await service.agencyService.get('GetMarketingItemsForStaff', {
    agencyId: agencyId,
  })

  return result.data
}

export default getMarketingItemsForStaff
