import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import { TeamMember } from '../../../types/agency'
import AgencyService from '../../agenciesService'

interface SetAgentDetailsRequest extends TeamMember, ApiRequestBody {}

const SetAgentDetails = async (
  requestBody: SetAgentDetailsRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put('SetAgentDetails', {
    ...requestProperties,
  })

  return result.status
}

export default SetAgentDetails
