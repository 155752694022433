import React from 'react'
import {
  AgxBodyText,
  AgxButton,
  AgxCheckbox,
  AgxColumn,
  AgxDivider,
  AgxHeader,
  AgxRow,
  AgxSlideUpModal,
} from '@urbanx/agx-ui-components'

interface Props {
  setShowModal: (showModal: boolean) => void
  onConfirm: () => void
  onNotifyAgentToggle: (checked: boolean) => void
}

export const SetBlankContractConfirmModal = (props: Props) => {
  const { setShowModal, onConfirm, onNotifyAgentToggle } = props
  const confirmationContent = (
    <AgxColumn veryLargeGap veryLargeSidePadding>
      <AgxHeader size={3}>Set Blank Contract live?</AgxHeader>
      <AgxDivider />
      <AgxBodyText>
        <AgxCheckbox
          subLabel={'Notify Agent'}
          id={'chkNotifyAgent'}
          onValueChanged={({ value }) => onNotifyAgentToggle(value)}
        />
      </AgxBodyText>

      <AgxRow veryLargeGap>
        <AgxButton
          primary
          large
          text="Finalise Blank Contract"
          onClick={() => {
            onConfirm()
            setShowModal(false)
          }}
        />
        <AgxButton
          hollow
          large
          text="Cancel"
          onClick={() => setShowModal(false)}
        />
      </AgxRow>
    </AgxColumn>
  )

  return (
    <AgxSlideUpModal
      closeButton
      content={confirmationContent}
      desktop={true}
      onClose={() => setShowModal(false)}
    />
  )
}

export default SetBlankContractConfirmModal
