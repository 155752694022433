import { Campaign } from 'types/campaigns'
import {
  AgxRow,
  AgxColumn,
  AgxButton,
  UploadedDocument,
  AgxBodyText,
  DocumentTypes,
  DocumentTypesMap
} from '@urbanx/agx-ui-components'
import { useQuery } from '@tanstack/react-query'
import {
  GetAllAgencies, GetCampaignsForAgency,
  GetDocumentsForCampaigns, StaffGetUploadedFileLink
} from 'services'
import { useContext, useEffect, useState } from 'react'
import { ContentLayout, PageLayout } from 'layout'
import { AgencyContext, UserContext } from 'contexts'
import { Agency } from 'types/agency'
import { useParams } from 'react-router-dom'
import { MenuTitles } from 'constants/menu'
import { useAzureAuth } from 'hooks/useAzureAuth'
import './Submissions.scss'
import { DocumentGroup } from 'services/functions/campaigns/getDocumentsForCampaigns'
import DocumentsList from './DocumentsList'


const Submissions = () => {
  const { agencyId, campaignId } = useParams()
  const user = useContext(UserContext)
  const [selectedCampaign, setSelectedCampaign] = useState<
    Campaign | undefined
  >()
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)

  const [, getAuthToken] = useAzureAuth()

  const { data: docInfoGroups, isLoading: areDocumentsLoading } = useQuery<DocumentGroup[] | undefined>({
    queryKey: [
      `campaign-documents-${selectedCampaign?.id}`,
      selectedCampaign?.id,
      0,
      10,
    ],
    queryFn: (queryKey) => GetDocumentsForCampaigns(queryKey, getAuthToken),
    enabled: selectedCampaign?.id != null,
  })

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const { data: campaigns } = useQuery<Campaign[] | undefined>({
    queryKey: [`campaigns-${agencyId}`, agencyId],
    queryFn: (queryKey) => GetCampaignsForAgency(queryKey, getAuthToken),
  })

  const getDocumentTypeLabel = (documentType: string) => 
    DocumentTypesMap[documentType as DocumentTypes]

  async function downloadFile(fileUrl: string, fileName: string) {
    try {
      const response = await fetch(fileUrl)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const blob = await response.blob()
      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = decodeURIComponent(fileName)
      link.style.display = 'none'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Release the reference to the blob
      setTimeout(() => URL.revokeObjectURL(blobUrl), 100)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  const downloadDocument = async (document: UploadedDocument) => {
    const documentLink = await StaffGetUploadedFileLink(
      {
        queryKey: [
          `document-link-${document.documentId}`,
          document.containerFilePath,
        ],
      },
      getAuthToken
    )

    if (!documentLink) {
      console.error('Error getting the file link')
      return
    }

    await downloadFile(documentLink, document.fileName)
  }

  const downloadAllDocuments = async () => {
    if (docInfoGroups)
      docInfoGroups.map(docGroup =>
        docGroup.documents.forEach(
          docGroup => downloadDocument(docGroup)
        )
      )
  }

  useEffect(() => {
    if (!selectedAgency) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies])

  useEffect(() => {
    if (!selectedCampaign && campaigns) {
      setSelectedCampaign(campaigns.find((c) => c.id === campaignId))
    }
  }, [campaigns])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Campaigns"
      isBlankContract={true}
      selectedCampaign={selectedCampaign}
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.FILES}
        isBlankContract={true}
      >
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween extraClasses="borderBottomContainer">
            <AgxRow largeGap>
              <AgxBodyText large>Files</AgxBodyText>
            </AgxRow>
            <AgxButton
              text="Download all"
              medium hollow
              onClick={downloadAllDocuments}
            />
          </AgxRow>
          {!areDocumentsLoading && docInfoGroups ?
            <DocumentsList
              documentsInfo={docInfoGroups}
              downloadDocument={downloadDocument}
              getDocumentTypeLabel={getDocumentTypeLabel}
              /> :
            <AgxBodyText medium>No documents found</AgxBodyText>
          }
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default Submissions
