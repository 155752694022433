/* eslint-disable react/prop-types */
import {
  AgxMultiOptionButton,
  formatPercentage,
} from '@urbanx/agx-ui-components'
import downloadButton from '../../assets/images/cloud-download.png'
import { useCallback, useState } from 'react'
import {
  NetworkPartnershipMetricData,
  NetworkStateMetricData,
} from '../../types/networkStatistics'
import { ContractDateToUse } from '../../types/rex'

const downloadCsv = (csvString: string, filename: string) => {
  const blob = new Blob([csvString], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

interface ExportDropdownProps {
  networkMetrics: string
  stateData: NetworkStateMetricData
  partnershipData: NetworkPartnershipMetricData
  selectedFilter: ContractDateToUse
}

const ExportDropdown: React.FC<ExportDropdownProps> = ({
  networkMetrics,
  stateData,
  partnershipData,
  selectedFilter,
}) => {
  const [showExport, setShowExport] = useState(false)

  const handlePDFExport = () => {
    window.print()
    setShowExport(!showExport)
  }

  const handleCSVExport = useCallback(() => {
    let csvString = ''
    let filename = 'export.csv'

    if (networkMetrics == 'state') {
      filename = `state.csv`
      csvString = stateToCsv(stateData, selectedFilter)
    } else {
      filename = `partnership.csv`
      csvString = partnershipToCsv(partnershipData, selectedFilter)
    }

    downloadCsv(csvString, filename)
    setShowExport(!showExport)
  }, [networkMetrics, stateData, selectedFilter])

  const stateToCsv = useCallback(
    (
      stateStatistics: NetworkStateMetricData,
      selectedFilter: ContractDateToUse
    ): string => {
      let filteredStatistics

      if (selectedFilter === ContractDateToUse.Settled) {
        filteredStatistics = stateStatistics?.settledMetrics ?? []
      } else if (selectedFilter === ContractDateToUse.Written) {
        filteredStatistics = stateStatistics?.writtenMetrics ?? []
      } else if (selectedFilter === ContractDateToUse.Unconditional) {
        filteredStatistics = stateStatistics?.unconditionalMetrics ?? []
      }

      const tableData =
        filteredStatistics?.map((stat) => ({
          title: stat?.state ?? '0',
          listings: stat?.listings ?? 0,
          contracts: stat?.contracts ?? 0,
          salesVolume: stat?.salesVolume ?? 0,
          avgSalePrice: stat?.avgSalesPrice ?? 0,
          gci: stat?.gci ?? 0,
          avgComm: formatPercentage(stat?.avgCommission?.toString() ?? '0', 2),
          serviceFee: stat?.serviceFee ?? 0,
          avgDom: stat?.avgDom ?? 0,
          percentNetwork: formatPercentage(
            stat?.percentage?.toString() ?? '0',
            2
          ),
        })) ?? []

      const headers = [
        'State',
        'Listings',
        'Contracts',
        'Sales Volume',
        'AV Sale Price',
        'GCI',
        'AV Comm Rate',
        'Service Fee',
        'Percent Network',
      ]

      const csvRows = [
        headers.join(','),
        ...tableData.map((stat) =>
          [
            stat.title.replaceAll(',', ''),
            stat.listings.toString().replaceAll(',', ''),
            stat.contracts.toString().replaceAll(',', ''),
            stat.salesVolume.toString().replaceAll(',', ''),
            stat.avgSalePrice.toString().replaceAll(',', ''),
            stat.gci.toString().replaceAll(',', ''),
            stat.avgComm.replaceAll(',', ''),
            stat.serviceFee.toString().replaceAll(',', ''),
            stat.percentNetwork.replaceAll(',', ''),
          ].join(',')
        ),
      ].join('\n')

      return csvRows
    },
    []
  )

  const partnershipToCsv = useCallback(
    (
      partnershipData: NetworkPartnershipMetricData,
      selectedFilter: ContractDateToUse
    ): string => {
      let filteredStatistics

      if (selectedFilter === ContractDateToUse.Settled) {
        filteredStatistics = partnershipData?.settledMetrics ?? []
      } else if (selectedFilter === ContractDateToUse.Written) {
        filteredStatistics = partnershipData?.writtenMetrics ?? []
      } else if (selectedFilter === ContractDateToUse.Unconditional) {
        filteredStatistics = partnershipData?.unconditionalMetrics ?? []
      }

      const tableData =
        filteredStatistics?.map((stat) => ({
          title: stat?.partnershipLevel ?? '0',
          listings: stat?.listings ?? 0,
          contracts: stat?.contracts ?? 0,
          salesVolume: stat?.salesVolume ?? 0,
          avgSalePrice: stat?.avgSalesPrice ?? 0,
          gci: stat?.gci ?? 0,
          avgComm: formatPercentage(stat?.avgCommission?.toString() ?? '0', 2),
          serviceFee: stat?.serviceFee ?? 0,
          avgDom: stat?.avgDom ?? 0,
          percentNetwork: formatPercentage(
            stat?.percentage?.toString() ?? '0',
            2
          ),
        })) ?? []

      const headers = [
        'State',
        'Listings',
        'Contracts',
        'Sales Volume',
        'AV Sale Price',
        'GCI',
        'AV Comm Rate',
        'Service Fee',
        'Percent Network',
      ]

      const csvRows = [
        headers.join(','),
        ...tableData.map((stat) =>
          [
            stat.title.replaceAll(',', ''),
            stat.listings.toString().replaceAll(',', ''),
            stat.contracts.toString().replaceAll(',', ''),
            stat.salesVolume.toString().replaceAll(',', ''),
            stat.avgSalePrice.toString().replaceAll(',', ''),
            stat.gci.toString().replaceAll(',', ''),
            stat.avgComm.replaceAll(',', ''),
            stat.serviceFee.toString().replaceAll(',', ''),
            stat.percentNetwork.replaceAll(',', ''),
          ].join(',')
        ),
      ].join('\n')

      return csvRows
    },
    []
  )

  return (
    <div>
      <AgxMultiOptionButton
        primary
        medium
        options={[
          {
            text: 'PDF file',
            onClick: handlePDFExport,
          },
          {
            text: 'CSV file',
            onClick: handleCSVExport,
          },
        ]}
        rightIcon={
          <img src={downloadButton} style={{ width: '18px', height: '18px' }} />
        }
        text="Export"
      />
    </div>
  )
}

export default ExportDropdown
