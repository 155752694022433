import { useContext, useState } from 'react'
import KeyMetricsButton from './ButtonKeyMetrics'
import RunRateButton from './ButtonRunRate'
import TableKeyMetrics from './TableKeyMetrics'
import TableRunRate from './TableRunRate'
import YearMonthDropdown from './YearMonthDropdown'
import './reporting.scss'
import './SetBudget.scss'
import { AgxColumn, AgxRow } from '@urbanx/agx-ui-components'
import SetBudget from './SetBudget'
import ExportDropdown from './ExportDropdown'
import {
  ExecutiveStatistics,
  RunRateInformation,
} from 'types/executiveStatistics'
import { GetExecutiveStatistics, GetRunRateStatistics } from 'services'
import { useQuery } from '@tanstack/react-query'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { calculateMonthDifference } from './CalculateMonthDifference'
import { PageLayout } from 'layout'
import { UserContext } from 'contexts'

const StaffReporting = () => {
  const [, getAuthToken] = useAzureAuth()
  const user = useContext(UserContext)

  const { data: keyMetricsStatistics } = useQuery<
    ExecutiveStatistics[] | undefined
  >({
    queryKey: ['executive-statistics'],
    queryFn: () => GetExecutiveStatistics(getAuthToken),
  })

  const { data: runRateStatistics } = useQuery<
    RunRateInformation[] | undefined
  >({
    queryKey: ['runrate-statistics'],
    queryFn: () => GetRunRateStatistics(getAuthToken),
  })

  const [tableMetrics, setTableMetrics] = useState('keyMetrics')
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const date = new Date()
    return date.toLocaleString('default', { month: 'long', year: 'numeric' })
  })

  const month = calculateMonthDifference(selectedMonth)

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName="Bayside"
      currentPageTitle="Executive Report"
      isStaff
    >
      <AgxColumn extraClasses="executive-dashboard-content">
        <AgxRow spaceBetween centered extraClasses="metrics-header">
          <AgxRow extraClasses="metrics-header-left">
            <KeyMetricsButton
              setTableMetrics={setTableMetrics}
              isActive={tableMetrics === 'keyMetrics'}
            />
            <RunRateButton
              setTableMetrics={setTableMetrics}
              isActive={tableMetrics === 'runRate'}
            />
          </AgxRow>
          {tableMetrics === 'keyMetrics' && (
            <div className="metrics-header-right">
              <YearMonthDropdown
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
              <SetBudget />
              {runRateStatistics && keyMetricsStatistics && (
                <ExportDropdown
                  tableMetrics={tableMetrics}
                  keyMetricsData={keyMetricsStatistics[month]}
                  runRateData={runRateStatistics[month]}
                />
              )}
            </div>
          )}
          {tableMetrics === 'runRate' && (
            <div className="metrics-header-right">
              <YearMonthDropdown
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
              {runRateStatistics && keyMetricsStatistics && (
                <ExportDropdown
                  tableMetrics={tableMetrics}
                  keyMetricsData={keyMetricsStatistics[month]}
                  runRateData={runRateStatistics[month]}
                />
              )}
            </div>
          )}
        </AgxRow>
        {tableMetrics === 'keyMetrics' && keyMetricsStatistics && (
          <TableKeyMetrics
            date={selectedMonth}
            keyMetricsData={keyMetricsStatistics[month]}
          />
        )}
        {tableMetrics === 'runRate' && runRateStatistics && (
          <TableRunRate
            date={selectedMonth}
            runRateData={runRateStatistics[month]}
          />
        )}
      </AgxColumn>
    </PageLayout>
  )
}

export default StaffReporting
