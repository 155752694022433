import CampaignsService from '../../campaignsService'
import { CampaignOwnership } from '../../../types/campaigns'
import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'

interface SetCampaignOwnershipRequest
  extends CampaignOwnership,
    ApiRequestBody {}

const setCampaignOwnership = async (
  requestBody: SetCampaignOwnershipRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const result = await service.campaignsService.post('SetCampaignOwnership', {
    ...requestProperties,
  })

  return result.status
}

export default setCampaignOwnership
