export const calculateMonthDifference = (selectedYearMonth: string) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth(); // Note: January is 0, December is 11
  
    const [selectedMonthName, selectedYear] = selectedYearMonth.split(' ');
    const selectedDate = new Date(`${selectedMonthName} 1, ${selectedYear}`);
    const selectedMonth = selectedDate.getMonth();
  
    const yearDiff = currentYear - selectedDate.getFullYear();
    const monthDiff = currentMonth - selectedMonth;
    
    // Calculate the difference in months, considering the year difference
    return (yearDiff * 12) + monthDiff;
  };  