import { AgxHeader } from '@urbanx/agx-ui-components';
import React from 'react';
import "./reporting.scss"

interface RunRateButtonProps {
  setTableMetrics: (metric: string) => void;
  isActive: boolean;
}

const RunRateButton: React.FC<RunRateButtonProps> = ({ setTableMetrics, isActive }) => {
  const buttonStyle = isActive ? 'activeHeader' : 'inactiveHeader';
  return (
    <div className={`run-rate-header ${buttonStyle}`} onClick={() => setTableMetrics('runRate')}>
      <AgxHeader size={5}>Run Rate</AgxHeader>
    </div>
  );
};

export default RunRateButton;