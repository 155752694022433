import { useContext, useMemo, useState } from 'react'
import './network.scss'
import {
  AgxColumn,
  AgxDatePicker,
  AgxHeader,
  AgxRow,
} from '@urbanx/agx-ui-components'
import ExportDropdown from './ExportDropdown'
import { useQuery } from '@tanstack/react-query'
import { useAzureAuth } from 'hooks/useAzureAuth'
import TableNetworkPartnership from './TableNetworkPartnership'
import TableNetworkState from './TableNetworkState'
import FilterDropdown from './FilterDropdown'
import {
  NetworkPartnershipMetricData,
  NetworkStateMetricData,
} from 'types/networkStatistics'
import { GetNetworkPartnershipMetrics, GetNetworkStateMetrics } from 'services'
import { PageLayout } from 'layout'
import { UserContext } from 'contexts'
import moment from 'moment'
import { formatDateRange } from 'utils/date'
import { ContractDateToUse } from 'types/rex'

export enum NetworkSelectedTab {
  State = 'state',
  PartnershipLevel = 'partnershipLevel',
}

const Network = () => {
  const [, getAuthToken] = useAzureAuth()
  const user = useContext(UserContext)
  const [networkMetrics, setNetworkMetrics] = useState(NetworkSelectedTab.State)
  const [selectedFilter, setSelectedFilter] = useState(
    ContractDateToUse.Written
  )

  const [selectedFirstDate, setSelectedFirstDate] = useState(() => {
    return moment().startOf('month').format('YYYY-MM-DD')
  })

  const [selectedSecondDate, setSelectedSecondDate] = useState(() => {
    return moment().format('YYYY-MM-DD')
  })

  const { data: stateStatistics } = useQuery<
    NetworkStateMetricData | undefined
  >(['state-statistics', selectedFirstDate, selectedSecondDate], () =>
    GetNetworkStateMetrics(
      {
        periodStart: selectedFirstDate,
        periodEnd: selectedSecondDate,
      },
      getAuthToken
    )
  )

  const { data: partnershipStatistics } = useQuery<
    NetworkPartnershipMetricData | undefined
  >(['partnership-statistics', selectedFirstDate, selectedSecondDate], () =>
    GetNetworkPartnershipMetrics(
      {
        periodStart: selectedFirstDate,
        periodEnd: selectedSecondDate,
      },
      getAuthToken
    )
  )

  const dateRange = useMemo(() => {
    return formatDateRange(selectedFirstDate, selectedSecondDate)
  }, [selectedFirstDate, selectedSecondDate])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName="Bayside"
      currentPageTitle="Network"
      isStaff
    >
      <div className="network-background">
        <AgxColumn extraClasses="executive-dashboard-content">
          <AgxRow spaceBetween centered extraClasses="metrics-header">
            <AgxRow extraClasses="metrics-header-left">
              <AgxHeader
                size={5}
                extraClasses={`key-metrics-header ${
                  networkMetrics === NetworkSelectedTab.State
                    ? 'activeheader'
                    : 'inactiveHeader'
                }`}
                onClick={() => setNetworkMetrics(NetworkSelectedTab.State)}
              >
                State
              </AgxHeader>
              <AgxHeader
                size={5}
                extraClasses={`run-rate-header ${
                  networkMetrics === NetworkSelectedTab.PartnershipLevel
                    ? 'activeheader'
                    : 'inactiveHeader'
                }`}
                onClick={() =>
                  setNetworkMetrics(NetworkSelectedTab.PartnershipLevel)
                }
              >
                Partnership Level
              </AgxHeader>
            </AgxRow>
            {networkMetrics === NetworkSelectedTab.State && (
              <div className="metrics-header-right">
                <FilterDropdown
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
                <AgxDatePicker
                  id="networkDateOne"
                  date
                  noOptionalLabel
                  stretch
                  defaultValue={selectedFirstDate}
                  onValueChanged={(e) => setSelectedFirstDate(e.value)}
                />
                <AgxDatePicker
                  id="networkDateTwo"
                  date
                  noOptionalLabel
                  stretch
                  defaultValue={selectedSecondDate}
                  onValueChanged={(e) => setSelectedSecondDate(e.value)}
                />
                {stateStatistics && partnershipStatistics && (
                  <ExportDropdown
                    networkMetrics={networkMetrics}
                    stateData={stateStatistics}
                    partnershipData={partnershipStatistics}
                    selectedFilter={selectedFilter}
                  />
                )}
              </div>
            )}
            {networkMetrics === NetworkSelectedTab.PartnershipLevel && (
              <div className="metrics-header-right">
                <FilterDropdown
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
                <AgxDatePicker
                  id="networkDateOne"
                  date
                  noOptionalLabel
                  stretch
                  defaultValue={selectedFirstDate}
                  onValueChanged={(e) => setSelectedFirstDate(e.value)}
                />
                <AgxDatePicker
                  id="networkDateTwo"
                  date
                  noOptionalLabel
                  stretch
                  defaultValue={selectedSecondDate}
                  onValueChanged={(e) => setSelectedSecondDate(e.value)}
                />
                {stateStatistics && partnershipStatistics && (
                  <ExportDropdown
                    networkMetrics={networkMetrics}
                    stateData={stateStatistics}
                    partnershipData={partnershipStatistics}
                    selectedFilter={selectedFilter}
                  />
                )}
              </div>
            )}
          </AgxRow>
          {networkMetrics === NetworkSelectedTab.State && stateStatistics && (
            <TableNetworkState
              dateRange={dateRange}
              stateStatistics={stateStatistics}
              selectedFilter={selectedFilter}
            />
          )}
          {networkMetrics === NetworkSelectedTab.PartnershipLevel &&
            partnershipStatistics && (
              <TableNetworkPartnership
                dateRange={dateRange}
                partnershipData={partnershipStatistics}
                selectedFilter={selectedFilter}
              />
            )}
        </AgxColumn>
      </div>
    </PageLayout>
  )
}

export default Network
