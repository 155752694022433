import PdfService from '../../pdfService'

export const GetPdfLicenseKey = async (
  authToken: string
): Promise<string | undefined> => {
  if (!authToken) return

  const service = new PdfService(authToken)
  if (!service) return

  const result = await service.pdfService.get('GetPdfLicenseKey')

  return result.data
}