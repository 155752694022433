import { QueryKey } from '@tanstack/react-query'
import { PartnershipStatistics } from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types'
import RexService from 'services/rexService'

const staffGetAgencyPartnershipDetails = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<PartnershipStatistics | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agencyId = queryKey[1] as string
  const anniversaryDate = queryKey[2] as string

  const service = new RexService(token)

  if (!service) return undefined

  const result = await service.rexService.get(
    'StaffGetAgencyPartnershipDetails',
    {
      agencyId,
      specificAnniversaryDate: anniversaryDate,
    }
  )

  return result.data
}

export default staffGetAgencyPartnershipDetails
